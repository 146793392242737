import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { ProvidersComponent } from '../components/providers/providers.component';
import { LoginComponent } from '../components/account/login/login.component';
import { LogoutComponent } from '../components/account/logout/logout.component';
import { ProviderListDetailComponent } from '../components/provider-list-detail/provider-list-detail.component';
import { AuthGuard } from '../utilities/auth.guard';
import { ForgotPasswordComponent } from '../components/account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../components/account/reset-password/reset-password.component';
import { GroupListComponent } from '../components/group-list/group-list.component';
import { ManageUsersComponent } from '../components/manage-users/manage-users.component';
import { CreatePasswordComponent } from '../components/manage-users/create-password/create-password.component';
import { ReportsComponent } from '../components/reports/reports.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { LimosComponent } from '../components/limos/limos.component';

//smart merge
import { SmartMergeComponent } from '../components/providers/smart-merge/smart-merge.component';
import { SmartMergeMatchesComponent } from '../components/providers/smart-merge-matches/smart-merge-matches.component';
import { SearchComponent } from '../components/search/search.component';
import { PredashboardOverlayComponent } from '../components/predashboard-overlay/predashboard-overlay.component';
import { ProviderDetailComponent } from '../components/provider-detail/provider-detail.component';
import { ScrubComponent } from '../components/scrub/scrub.component';
import { UnmergeComponent } from '../components/unmerge/unmerge.component';
import { ClaimantSummaryComponent } from '../components/limos/claimant-summary/claimant-summary.component';
import { EntitiesListComponent } from '../components/entities-list/entities-list.component';
import { EntitiesDetailComponent } from '../components/entities-detail/entities-detail.component';
import { UnderMaintenanceComponent } from '../components/maintenance/under-maintenance/under-maintenance.component';
import { LimosReportComponent } from '../components/limos/limos-report/limos-report.component';
import { LimosInvoiceComponent } from '../components/limos/limos-invoice/limos-invoice.component';
import { LimosInvoicePdfComponent } from '../components/limos/limos-invoice-pdf/limos-invoice-pdf.component';
import { EntitiesDetailNewComponent } from '../components/entities-detail-new/entities-detail-new.component';
import { CaseComponent } from '../components/case/case.component';
import { InvoiceComponent } from '../components/invoice/invoice.component';
import { ClientComponent } from '../components/client/client.component';
import { ResourcesComponent } from '../components/resources/resources.component';
import { ClientDetailComponent } from '../components/client/client-detail/client-detail.component';
import { ContactComponent } from '../components/contact/contact.component';
import { ContactDetailComponent } from '../components/contact/contact-detail/contact-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'merge', component: ProvidersComponent, canActivate: [AuthGuard] },
  { path: 'unmerge', component: UnmergeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  {
    path: 'provider-detail/:id',
    component: ProviderListDetailComponent,
    canActivate: [AuthGuard],
  },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'matters', component: GroupListComponent, canActivate: [AuthGuard] },
  {
    path: 'entities',
    component: EntitiesListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-users',
    component: ManageUsersComponent,
    canActivate: [AuthGuard],
  },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'providers/smart-merge', component: SmartMergeComponent },
  { path: 'providers/manual-merge', component: SmartMergeMatchesComponent },
  { path: 'scrub', component: ScrubComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-report/:id/:type_id',
    component: ProviderDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'entities-report-old/:id',
    component: EntitiesDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'entities-report/:id',
    component: EntitiesDetailNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'entity/:id',
    component: ProviderDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'limos/:claimNumber/:limos_id',
    component: LimosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'claimant-summary/:claimant_id/:limos_id',
    component: ClaimantSummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'limos/report',
    component: LimosReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'limos/invoice-pdf',
    component: LimosInvoicePdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'limos/invoice',
    component: LimosInvoiceComponent,
    canActivate: [AuthGuard],
  },
  { path: 'cases', component: CaseComponent, canActivate: [AuthGuard] },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent,
    canActivate: [AuthGuard],
  },
  { path: 'invoices', component: InvoiceComponent, canActivate: [AuthGuard] },
  { path: 'clients', component: ClientComponent, canActivate: [AuthGuard] },
  {
    path: 'client/:id',
    component: ClientDetailComponent,
    canActivate: [AuthGuard],
  },
  { path: 'contacts', component: ContactComponent, canActivate: [AuthGuard] },
  {
    path: 'contact/:id',
    component: ContactDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
