import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  ClientDataResponse,
  ClientCreateResponse,
} from 'src/app/models/client/client.interface';
import {
  CreateInvoiceReq,
  GetTimeSpentResponseDetail,
  LimosDataResponse,
  LimosInvoiceData,
  UpdateLimosResponseDetails,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';

@Component({
  selector: 'app-limos-invoice',
  templateUrl: './limos-invoice.component.html',
  styleUrls: ['./limos-invoice.component.css'],
})
export class LimosInvoiceComponent implements OnInit {
  termsCollapse = false;
  publicNotesCollapse = false;
  internalNotesCollapse = false;
  limosInvoiceData: LimosInvoiceData;
  public serverDateFormat = 'yyyy-MM-dd';
  private unsubscribe$ = new Subject<void>();

  public timeTypes = [
    'Initial Lien Review',
    'Lien Negotiation',
    'Client Correspondence',
    'D/A Correspondence',
    'Other',
  ].sort();

  constructor(
    private limosService: LimosService,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.limosService.limosInvoiceData.subscribe(
      (limosInvoiceData: LimosInvoiceData) => {
        this.limosInvoiceData = limosInvoiceData;
      }
    );
  }

  get providerNames(): string {
    return this.limosInvoiceData.time_spents
      .map((item) => item.provider_name_business)
      .join(',');
  }

  addLienItem() {
    this.limosInvoiceData.time_spents.push({
      id: null,
      user: '',
      provider_name_business: '',
      time_type: '',
      note: '',
      time_spent: '',
      log_date: '',
      rate: null,
      total: null,
      checked: false,
    });
  }

  cancelInvoice() {
    this.location.back();
  }

  onChangeRate(timeSpent: GetTimeSpentResponseDetail) {
    const totalSpent = timeSpent.rate * +timeSpent.time_spent.split(' ')[0];
    timeSpent.total = isNaN(totalSpent) ? 0 : totalSpent;
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents.reduce(
      (acc, curr) => acc + (curr.total ?? 0),
      0
    );
    return isNaN(total) ? 0 : total;
  }

  saveInvoice() {
    const {
      created_on,
      due_on,
      billing_to,
      billing_location,
      billing_contact,
      billing_email,
      profile_id,
      claim_number,
      claimant_name,
      provider_name,
      time_spents,
    } = this.limosInvoiceData;
    const req: CreateInvoiceReq = {
      created_on: this.datePipe.transform(created_on, this.serverDateFormat),
      due_on: this.datePipe.transform(due_on, this.serverDateFormat),
      bill_to: billing_to,
      location: billing_location,
      contact: billing_contact,
      billing_email: billing_email,
      limos_case_number: profile_id,
      claim_number,
      claimant_name,
      provider_name,
      time_logs: time_spents?.length
        ? time_spents.map((time_spent) => time_spent.id)
        : [],
    };

    this.limosService
      .createInvoice(req)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
          this.toastr.success(res?.details?.message);
          this.limosService.setlimosInvoiceData(this.limosInvoiceData);
          this.router.navigate(['/limos/invoice-pdf']);
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  submitInvoiceData(index: number) {
    const {
      id,
      log_date,
      note,
      provider_name_business,
      time_spent,
      time_type,
      rate,
      total,
    } = this.limosInvoiceData.time_spents[index];

    if (id) {
      this.limosService
        .updateTimeSpent(id, {
          log_date: this.datePipe.transform(log_date, this.serverDateFormat),
          note,
          provider_name_business,
          time_spent: Math.round(Number(time_spent.replace(' Hrs', '')) * 3600),
          time_type,
          rate,
          total,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
            this.toastr.success(res?.details?.message);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
    } else {
      this.limosService
        .saveTimeSpent({
          log_date: this.datePipe.transform(log_date, this.serverDateFormat),
          note,
          provider_name_business,
          time_spent: Math.round(Number(time_spent.replace(' Hrs', '')) * 3600),
          time_type,
          rate,
          total,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
            this.toastr.success(res?.details?.message);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
