<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header spear-table-header-background">
        <h6>Invoices</h6>
      </div>
      <div class="card-body" *blockUI="'invoice-list-ui'">
        <table class="table spear-table-header-background">
          <caption>
            NPI
          </caption>
          <thead>
            <tr>
              <th class="border-0" scope="col">Invoice No.</th>
              <th class="border-0" scope="col">Claim Number</th>
              <th class="border-0" scope="col">Claimant Name</th>
              <th class="border-0" scope="col">Provider Name</th>
              <th class="border-0" scope="col">Invoice Program</th>
              <th class="border-0" scope="col">Case Number</th>
              <th class="border-0" scope="col">Created On</th>
              <th class="border-0" scope="col">Total</th>
              <th class="border-0" scope="col">Amount Paid</th>
            </tr>
          </thead>

          <tbody *ngIf="invoices?.length">
            <ng-container *ngFor="let invoice of invoices">
              <tr class="bg-white">
                <td>{{ invoice.invoice_number }}</td>
                <td>{{ invoice.claim_number }}</td>
                <td>{{ invoice.claimant_name }}</td>
                <td>{{ invoice.provider_name }}</td>
                <td></td>
                <td></td>
                <td>{{ invoice.created_on }}</td>
                <td>{{ invoice.total }}</td>
                <td>{{ invoice.amount_paid }}</td>
              </tr>
            </ng-container>
          </tbody>

          <ng-template *ngIf="!invoices?.length">
            <tbody>
              <tr class="bg-white">
                <td colspan="10" class="text-center">Data not available</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <div
          class="px-0 py-0 paginationWrapper d-flex"
          *ngIf="!(!invoices || invoices.length === 0)"
        >
          <ngb-pagination
            [(collectionSize)]="invoiceTotal"
            [(page)]="invoicePage"
            [pageSize]="invoiceLimit"
            [maxSize]="10"
            [boundaryLinks]="true"
            (pageChange)="onPageChangeinvoices($event)"
          >
          </ngb-pagination>
          <div class="selected d-flex align-items-center">
            <select
              (change)="onSizeChangeinvoices(invoiceLimit)"
              class="custom-select table-select h-100"
              [(ngModel)]="invoiceLimit"
            >
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="75">75</option>
              <option [ngValue]="100">100</option>
            </select>
            <p class="mb-0 ml-2 text-nowrap">
              Showing {{ invoicePage * invoiceLimit - invoiceLimit + 1 }}
              -
              {{
                invoicePage * invoiceLimit > invoiceTotal
                  ? invoiceTotal
                  : invoicePage * invoiceLimit
              }}
              of {{ invoiceTotal }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
