import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent implements OnInit {
  invoiceLimit = 20;
  invoicePage = 1;
  invoiceTotal: number;
  invoices: Invoice[];
  @BlockUI('invoice-list-ui') invoiceListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();

  constructor(private limosService: LimosService) {}

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.invoiceListBlockUI.start();
    this.limosService
      .getInvoiceList(this.invoiceLimit, this.invoicePage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<InvoicePaginationData>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.invoices = hits;
          this.invoiceTotal = estimatedTotalHits;
          this.invoiceListBlockUI.stop();
        },
        (error) => {
          this.invoiceListBlockUI.stop();
        }
      );
  }

  onPageChangeinvoices(event: any) {
    this.invoicePage = event;
    this.getInvoices();
  }

  onSizeChangeinvoices(limit: number) {
    this.invoiceLimit = limit;
    this.invoicePage = 1;
    this.getInvoices();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
