<div class="container-fluid">
    <div class="fixed-nav">
        <div class="fixed-nav-inner">
            <ul class="text-center">
                <li class="text-white">
                    <a class="text-white" (click)="showOverlay()">
                        <img alt="logo" class="logo" src="assets/images/icon-os-desktop-no_hexagons.png">
                    </a>
                    <hr>
                </li>
                <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                  <a class="text-white" routerLink="/dashboard" placement="right" ngbTooltip="Dashboard">
                    <em class="fa fa-tachometer"></em>
                  </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active'
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/search" placement="right" ngbTooltip="Search">
                        <img alt="search" src="assets/images/search-svg.svg">
                    </a>
                </li>
                <!-- <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/merge" placement="right" ngbTooltip="Merge">
                        <em class="fa fa-link"></em>
                    </a>
                </li>
                <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/unmerge" placement="right" ngbTooltip="Unmerge">
                        <em class="fa fa-chain-broken"></em>
                    </a>
                </li> -->
                <li class="text-gray">
                    <a class="text-white" placement="right" ngbTooltip="Entities">
                        <img alt="Entities" src="assets/images/entity.svg" >
                    </a>
                </li>
                <!-- <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                  <a class="text-white" routerLink="/limos/report" placement="right" ngbTooltip="Limos Report">
                      <em class="fa fa-file-text-o"></em>
                  </a>
                </li> -->
                <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/matters" placement="right" ngbTooltip="Matters">
                        <img alt="Matters" src="assets/images/layers.svg">
                    </a>
                </li>
                <li routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/reports" placement="right" ngbTooltip="Reports">
                        <em class="fa fa-files-o"></em>
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/scrub" placement="right" ngbTooltip="Scrub">
                        <img alt="Scrub" src="assets/images/file-process-icon.svg">
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" placement="right" ngbTooltip="Invoices" routerLink="/invoices">
                        <em class="fa fa-file-text-o"></em>
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" placement="right" ngbTooltip="Clients" routerLink="/clients">
                        <em class="fa fa-users"></em>
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" placement="right" ngbTooltip="Calender">
                        <em class="fa fa-calendar"></em>
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" placement="right" ngbTooltip="Maps">
                        <em class="fa fa-map-marker"></em>
                    </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                  <a class="text-white" routerLink="/resources" placement="right" ngbTooltip="Resources">
                      <em class="fa fa-folder-open"></em>
                  </a>
                </li>
                <li *ngIf="userRole === userType.investigator" routerLinkActive='active' [routerLinkActiveOptions]="{exact: true}">
                    <a class="text-white" routerLink="/manage-users" placement="right" ngbTooltip="Settings">
                        <em class="fa fa-cogs"></em>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
