import { LoginModel } from '../models/LoginModel';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordModel } from '../models/account/change.password.model';
import { ForgotPasswordModel } from '../models/account/forgot.password.model';
import { ResetPasswordModel } from '../models/account/reset.password.model';

@Injectable()
export class AccountService {
    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    login(model: LoginModel) {
        return this.http.post(environment.fireStoreApiUrl + '/api/v1/auth/token/', model);
    }
    // tslint:disable-next-line: typedef
    getCurrentUserProfile() {
        //return this.http.get(environment.apiUrl + 'account/get-current-user-profile');
        return this.http.get(environment.fireStoreApiUrl + '/api/v1/user/info/');
    }

    changePassword(model: ChangePasswordModel){
        return this.http.post(environment.apiUrl + 'account/change-password', model);
    }

    SendResetPasswordLink(model: ForgotPasswordModel) {
        return this.http.post(environment.apiUrl + 'account/send-reset-password-link', model);
    }

    resetPassword(model: ResetPasswordModel){
        return this.http.post(environment.apiUrl + 'account/reset-password', model);
    }
}
