<nav class="fixed-nav navbar navbar-expand navbar-light navbar-bg fixed-top">
    <ul>
        <li class="text-dark cursor-pointer pr-2" style="display: none;">
            <em class="fa fa-bars fa-2x"></em>
        </li>
    </ul>
    <form class="form-inline d-none d-sm-inline-block col-md-3 col-xl-4" ngNativeValidate *ngIf="providerService.ShowSearchBar">
        <div class="input-group input-group-navbar">
            <!-- <div class="input-group-append"> -->
            <!--Using items input-->
            <!-- <ng-select  ngClass="custom-select" placeholder="Select"  [multiple]="true" [(ngModel)]="selectedCriteria" [ngModelOptions]="{standalone: true}" required>
                    <ng-option *ngFor="let item of searchCriteriaSelectListItem" [value]="item">{{searchCriteria[item]}}</ng-option>
                </ng-select>
            </div> -->
            <input type="text" class="form-control search-control pl-0 border-0"
                placeholder="Search company, individual, name, alias name, etc." aria-label="Search" name="searchText"
                [(ngModel)]="searchText" (keydown.enter)="searchProviders()">
            <button class="btn rounded-left search-btn" type="button" (click)="searchProviders()">
                <em class="fa fa-search"></em>
            </button>
        </div>
    </form>

    <div class="col-md-3 col-xl-4" *ngIf="providerService.ShowButtonsInProvider">
        <div class="d-flex ">
            
                <button class="btn btn-secondary-outline bg-white text-voilet" (click)="backToProvider()">Back to search</button>
        
                <!-- <button class="btn viewBtn bg-voilet text-white ml-2">Investigation</button>
          
                <button class="btn viewBtn bg-voilet text-white ml-2">Add Data</button> -->
            
        </div>
    </div>

 

    <div class="navbar-collapse collapse justify-content-end">
        <ul class="navbar-nav navbar-align align-items-center">
            <li class="mr-3 border-right  pr-3">
                <div class="icon d-flex align-items-center py-2">
                    <div class="rounded-circle bg-pink card-icon text-center">
                        <em class="text-white align-middle fa fa-users"></em>
                    </div>
                    <div class="ml-3">
                        <h3 class="font-weight-bold mb-0">
                            {{ uniqueRecords }}
                        </h3>                       
                        <span class="">Unique Records</span>
                    </div>
                </div>
            </li>

            <li class="mr-3 border-right  pr-3">
                <div class="icon d-flex align-items-center">
                    <div class="rounded-circle bg-skyBlue card-icon text-center">
                        <em class="text-white align-middle fa fa-users"></em>
                    </div>
                    <div class="ml-3">
                        <!-- <h3 class="font-weight-bold mb-0">{{ recordsCount.newProviders | shortNumber}}</h3> -->
                        <h3 class="font-weight-bold mb-0">{{ totalSource }}</h3>
                        <span class="">Total Sources</span>
                    </div>
                </div>
            </li>

            <li class="border-right mr-3  pr-3">
                <div class="icon d-flex align-items-center">
                    <div class="rounded-circle bg-lightWarning card-icon text-center">
                        <em class="text-white align-middle fa fa-users"></em>
                    </div>
                    <div class="ml-3">
                        <h3 class="font-weight-bold mb-0">
                            {{ sprCount }}
                        </h3>
                        <span class="">SPEAR IQ</span>
                    </div>
                </div>
            </li>
           
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle d-none d-flex align-items-center" data-toggle="dropdown"
                    aria-expanded="false">
                    <img alt="Avatar" src="assets/images/avatar.png" class="avatar img-fluid rounded-circle mr-2">
                    <span class="primary-text viewProfile">
                        <!-- {{userProfile?.details?.role }}<br> -->
                        <span class="admin-name">
                            {{userProfile?.details?.name }}
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right px-2">
                
                    <a class="dropdown-item px-2" (click)="captureScreen()"><em class="fa fa-camera mr-3"></em>Take Screenshot</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item px-2" (click)="onChangePasswordClick()"><em
                            class="fa fa-lock mr-3"></em>Change Password</a>


                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-voilet px-2" routerLink="/logout"><em
                            class="fa fa-sign-out mr-3"></em>Log out</a>
                </div>
            </li>
        </ul>
    </div>

    
</nav>



<div hidden id="download"> <img alt="canvas" #canvas> <a #downloadLink> </a> </div>