<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header spear-table-header-background">
        <h6>Invoices</h6>
      </div>
      <div class="card-body">
        <table class="table spear-table-header-background">
          <caption>NPI</caption>
          <thead>
            <tr>
              <th class="border-0" scope="col">Invoice No.</th>
              <th class="border-0" scope="col">Client Reference No.</th>
              <th class="border-0" scope="col">Secondary Reference</th>
              <th class="border-0" scope="col">Client</th>
              <th class="border-0" scope="col">Client Program</th>
              <th class="border-0" scope="col">Case Number</th>
              <th class="border-0" scope="col">Case Name</th>
              <th class="border-0" scope="col">Created On</th>
              <th class="border-0" scope="col">Total</th>
              <th class="border-0" scope="col">Amount Paid</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white">
              <td colspan="10" class="text-center">Data not available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
