<div class="container-fluid p-0 invoice-wrapper">
  <div class="row mb-1 w-100">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <div class="text-sm">
        <a class="text-gray" [routerLink]="['/limos',limosInvoiceData.claim_number,limosInvoiceData.profile_id]"><u>{{
          limosInvoiceData.profile_id }}</u></a> <i class="fa fa-angle-double-right mx-1"></i>
        <a class="text-gray" [routerLink]="['/limos/invoice']"><u>Edit</u></a> <i class="fa fa-angle-double-right mx-1"></i> Invoice
      </div>

      <div class="text-right">
        <button class="btn btn-sm btn-dark text-white" (click)="exportToPdf()">Download </button>
        <button class="btn btn-sm btn-warning mx-1" (click)="openModal(duplicateInvoiceCountModal,'sm')">Duplicate </button>
        <button class="btn btn-sm btn-secondary mx-1 text-white" (click)="openModal(sendEmailModal)">Send</button>
        <button class="btn btn-sm btn-info mx-1 text-white" (click)="openModal(paymentModal,'lg')">Enter Payment</button>
        <a class="btn btn-sm btn-primary mx-1 text-white" [routerLink]="['/limos/invoice']">Edit</a>
        <button class="btn btn-sm btn-danger ml-1 text-white" (click)="openModal(deleteInvoice,'sm')">Delete</button>
      </div>
    </div>
  </div>
  <div class="row w-100">
    <div class="col-md-12">
      <div class="card w-100">
        <div class="card-body mb-3 text-black-contrast text-sm" id="invoicePage">
          <div class="row mt-5 ml-4">
            <div class="col-8">
              <div>
                <b>Argus West Inc.</b>
              </div>
              <div>
                7183 NAVAJO RD., SUITE M
              </div>
              <div>
                SAN DIEGO, CA 92119
              </div>
              <div>
                (800) 944-2748
              </div>

              <div class="mt-3">
                TIN# 45-4294639
              </div>
            </div>

            <div class="col-4">
              <img src="assets/images/argus_logo.png" width="210px" alt="icon">
            </div>
          </div>

          <div class="row mt-5 ml-4">
            <div class="col-8">
              <div>
                <b>Sedgwick Claims Management Services, Inc.</b>
              </div>
              <div>
                Vincent Villafranca
              </div>
              <div>
                PO Box 14779
              </div>
              <div>Lexington, KY 40512</div>
            </div>

            <div class="col-4">
              <div class="row w-100 border-bottom border-top">
                <div class="col-6"><b>Invoice #:</b></div>
                <div class="col-6">{{ limosInvoiceData.invoice_number }}</div>
              </div>
              <div class="row w-100 border-bottom">
                <div class="col-6"><b>Case #:</b></div>
                <div class="col-6">
                  <a [routerLink]="['/limos',limosInvoiceData.claim_number,limosInvoiceData.profile_id]" [ngClass]="{'text-decoration-none text-black-contrast': isFileDownloading,'text-decoration-underline': !isFileDownloading}">{{
                      limosInvoiceData.profile_id }}</a>
                </div>
              </div>
              <div class="row w-100 border-bottom">
                <div class="col-6"><b>Claimant:</b></div>
                <div class="col-6">{{ limosInvoiceData.claimant_name }}</div>
              </div>
              <div class="row w-100 border-bottom">
                <div class="col-6"><b>Claim Number:</b></div>
                <div class="col-6">{{ limosInvoiceData.claim_number }}</div>
              </div>
              <div class="row w-100 border-bottom">
                <div class="col-6"><b>Invoice Date:</b></div>
                <div class="col-6">{{ limosInvoiceData.created_on }}</div>
              </div>
              <div class="row w-100 border-bottom ">
                <div class="col-6"><b>Payment Due:</b></div>
                <div class="col-6">{{ limosInvoiceData.due_on }}</div>
              </div>
              <div class="row w-100 border-bottom">
                <div class="col-6"><b>Amount Due:</b></div>
                <div class="col-6"><b>{{ finalTotalRate | decimalPoint | dollarCurrency }}</b></div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <div class="table mb-0" id="lienResTable">
                <table class="table invoice-table limos-table-header-background mb-0">

                  <thead>
                    <tr>
                      <th class="border-0">Date</th>
                      <th class="border-0">Time Type</th>
                      <th class="border-0" width="55%">Time Note</th>
                      <th class="border-0">Time Spent</th>
                      <th class="border-0">Rate</th>
                      <th class="border-0">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of limosInvoiceData.time_spents; let ind = index">
                      <td><span class="text-black-contrast text-sm">{{ row.log_date | date: clientDateFormat }}</span></td>
                      <td><span class="text-black-contrast text-sm">{{ row.time_type }}</span></td>
                      <td><span class="text-black-contrast text-sm">{{ row.note }}</span></td>
                      <td><span class="text-black-contrast text-nowrap text-sm">{{ row.time_spent }}</span></td>
                      <td> <span class="text-black-contrast text-nowrap text-sm">{{ row.rate }}</span> </td>
                      <td> <span class="text-black-contrast text-nowrap text-sm">{{ (row.total | decimalPoint | dollarCurrency)
                          || '$00.00' }}</span> </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td><b class="text-black-contrast text-sm">Total:</b></td>
                      <td><b class="text-black-contrast text-sm">{{ finalTotalRate | decimalPoint | dollarCurrency }}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 mb-3">
              <b>Terms</b>
            </div>
            <div class="col-12">
              <div><b>Notes</b></div>
              <div class="text-sm">Thank you for giving us the opportunity to provide our services as we truly
                appreciate your business.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteInvoice let-modal>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this invoice?</p>
    <div class="text-center">
      <button class="btn spear-button-background text-white mr-4" (click)="deleteInvoiceData()">
        Yes
      </button>
      <button class="btn spear-button-background text-white" (click)="modalService.dismissAll()">
        No
      </button>
    </div>
  </div>
</ng-template>

<ng-template #sendEmailModal let-modal>
  <div class="modal-header">
    <h6>Send <span class="text-dark">{{ limosInvoiceData.profile_id }}</span> by Email</h6>
    <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body send-email-modal text-sm">
      <div class="row mb-2">
        <div class="col-3 text-black-contrast fw-500 text-right">
          To:
        </div>
        <div class="col-9 d-flex">
          <ng-container *ngIf="!showBillingEmailInput;else billingEmailInput">
            {{ limosInvoiceData.billing_name}} ({{ limosInvoiceData.billing_email }})
          </ng-container>
          <ng-template #billingEmailInput>
            <input type="text" id="billing_email" name="billing_email" class="w-100 rounded border">
          </ng-template>
          <button class="btn btn-link btn-sm text-black" *ngIf="!showBillingEmailInput" (click)="showBillingEmailInput = !showBillingEmailInput">(change)</button>
          <button class="btn btn-link btn-sm text-black" *ngIf="showBillingEmailInput" (click)="showBillingEmailInput = !showBillingEmailInput">(cancel)</button>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3 text-black-contrast fw-500 text-right">
          Last Sent:
        </div>
        <div class="col-9">
          Never
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3 text-black-contrast fw-500 text-right">
          Additional Note:
        </div>
        <div class="col-9">
          <textarea name="email_note" id="email_note" rows="3" class="rounded border w-100"></textarea>
          <div class="mt-2">
            <input type="checkbox" name="mark_invoice_sent" id="mark_invoice_sent" class="mr-1">
            <label for="mark_invoice_sent"> Mark Invoice as Sent</label>
          </div>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button type="button" class="btn btn-secondary btn-sm text-white mr-1" (click)="modalService.dismissAll()">
            Cancel
          </button>
          <button type="button" class="btn limos-button-background btn-sm text-white">
            Send Invoice
          </button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #paymentModal let-modal>
  <div class="modal-header">
    <h6>Enter Payments</h6>
    <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body send-email-modal text-sm">

      <div class="card">
        <div class="card-header text-black-contrast">
          <a class="text-dark-bold" [routerLink]="['/limos',limosInvoiceData.claim_number,limosInvoiceData.profile_id]"><u>{{
            limosInvoiceData.profile_id }}</u></a> - {{ limosInvoiceData.billing_name}} ({{ limosInvoiceData.billing_email }})
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <label for="payment_amount">Amount</label>
              <div class="d-flex">
                <div>$</div> <input type="number" name="payment_amount" id="payment_amount" class="w-100 rounded border ml-1">
              </div>
            </div>

            <div class="col-3">
              <label for="payment_method">Method</label>
                <select type="number" name="payment_method" id="payment_method" class="w-100 rounded border bg-transparent h-50">
                  <option value="cash">Cash</option>
                  <option value="check">Check</option>
                  <option value="write-off">Write-off</option>
                </select>
            </div>

            <div class="col-3">
              <label for="payment_date">Date</label>
              <input type="text" id="payment_date" name="payment_date" class="rounded w-100 border" jDate>
            </div>

            <div class="col-3">
              <label for="payment_check_reference">Check Reference</label>
              <input type="text" id="payment_check_reference" name="payment_check_reference" class="rounded w-100 border">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <input type="checkbox" name="pay_in_full" id="pay_in_full" class="mr-1">
              <label for="pay_in_full"> Pay in full</label>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-3">
              <label for="payment_amount_due">Amount Due</label>
              <h3 class="text-dark">{{ finalTotalRate | decimalPoint | dollarCurrency }}</h3>
            </div>
            <div class="col-9">
              <label for="payment_amount_notes">Notes</label>
              <textarea name="payment_amount_notes" id="payment_amount_notes" rows="3" class="w-100 rounded border"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <input type="checkbox" name="send_payment_notification" id="send_payment_notification" class="mr-1">
          <label for="send_payment_notification"> Send payment notifications</label>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button type="button" class="btn btn-secondary btn-sm text-white mr-1" (click)="modalService.dismissAll()">
            Cancel
          </button>
          <button type="button" class="btn limos-button-background btn-sm text-white">
            Save Payments
          </button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #duplicateInvoiceCountModal let-modal>
  <div class="modal-header border-0">
    <h6>Enter Invoice Count</h6>
    <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body duplicate-invoice-count-modal text-sm">
      <div class="row">
        <div class="col-12">
          <input type="number" class="form-control text-center" [(ngModel)]="duplicateInvoiceCount">
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button type="button" class="btn btn-secondary btn-sm text-white mr-1" (click)="modalService.dismissAll()">
            Cancel
          </button>
          <button type="button" class="btn limos-button-background btn-sm text-white" (click)="setDuplicateInvoice(duplicateInvoice)">
            Save
          </button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #duplicateInvoice let-modal>
  <div class="modal-header border-0">
    <h6>Duplicate Invoice</h6>
    <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body duplicate-invoice text-sm">
      <div class="row">
        <div class="col-12">
          <div class="table mb-0" id="lienResTable">
            <table class="table invoice-table limos-table-header-background mb-0">

              <thead>
                <tr>
                  <th class="border-0 text-center">Sr. No.</th>
                  <th class="border-0 text-center">Claim Number</th>
                  <th class="border-0 text-center">Claimant Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let invoice of paginatedDuplicateInvoices; let ind = index">
                  <td>{{ (duplicateInvoicePage - 1) * duplicateInvoiceLimit + ind + 1 }}</td>
                  <td class="text-center">
                    <input type="number" [(ngModel)]="invoice.claimNumber" class="border-grey">
                  </td>
                  <td class="text-center">
                    <input type="text" [(ngModel)]="invoice.claimantName" class="border-grey">
                  </td>
                </tr>
                <tr *ngIf="!paginatedDuplicateInvoices?.length">
                  <td colspan="2"> Data not available</td>
                </tr>
              </tbody>
            </table>
            <div class="px-0 py-0 paginationWrapper mt-1" *ngIf="!(!duplicateInvoices || duplicateInvoices.length === 0)">
              <ngb-pagination [(collectionSize)]="duplicateInvoices.length" [(page)]="duplicateInvoicePage" [pageSize]="duplicateInvoiceLimit" [maxSize]="10"
                [boundaryLinks]="true" (pageChange)="onPageChangeDuplicateInvoice($event)">
              </ngb-pagination>
              <div class="selected align-items-center" id="duplicate-invoice-limit-dd">
                  <select (change)="onSizeChangeDuplicateInvoice(duplicateInvoiceLimit)" class="custom-select table-select h-100" [(ngModel)]="duplicateInvoiceLimit">
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="75">75</option>
                    <option [ngValue]="100">100</option>
                  </select>
                  <p class="mb-0 ml-2 text-nowrap">Showing {{ ((duplicateInvoicePage * duplicateInvoiceLimit) - duplicateInvoiceLimit) + 1 }}
                    -
                    {{ duplicateInvoicePage * duplicateInvoiceLimit > duplicateInvoices.length ? duplicateInvoices.length : duplicateInvoicePage *
                      duplicateInvoiceLimit }} of {{ duplicateInvoices.length }}</p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button type="button" class="btn btn-secondary btn-sm text-white mr-1" (click)="modalService.dismissAll()">
            Cancel
          </button>
          <button type="button" class="btn limos-button-background btn-sm text-white" (click)="modalService.dismissAll()">
            Save
          </button>
        </div>
      </div>
  </div>
</ng-template>
