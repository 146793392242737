import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DuplicateInvoice,
  LimosInvoiceData,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-limos-invoice-pdf',
  templateUrl: './limos-invoice-pdf.component.html',
  styleUrls: ['./limos-invoice-pdf.component.css'],
})
export class LimosInvoicePdfComponent implements OnInit, OnDestroy {
  limosInvoiceData: LimosInvoiceData;
  isFileDownloading = false;
  showBillingEmailInput = false;
  duplicateInvoiceCount: number;
  duplicateInvoices: DuplicateInvoice[];
  paginatedDuplicateInvoices: DuplicateInvoice[];
  duplicateInvoicePage = 1;
  duplicateInvoiceLimit = 20;

  constructor(
    private limosService: LimosService,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.limosService.limosInvoiceData.subscribe(
      (limosInvoiceData: LimosInvoiceData) => {
        console.log(limosInvoiceData);
        this.limosInvoiceData = limosInvoiceData;
      }
    );
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents.reduce(
      (acc, curr) => acc + (curr.total ?? 0),
      0
    );
    return isNaN(total) ? 0 : total;
  }

  exportToPdf() {
    this.isFileDownloading = true;
    var element = document.getElementById('invoicePage');
    var opt = {
      filename: `${this.limosInvoiceData.invoice_number}.pdf`,
      margin: 0.5,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf
      .default(element, opt)
      .then((result) => {
        console.log('print done', result);
        this.isFileDownloading = false;
        this.cd.detectChanges();
      })
      .catch((err) => {
        this.isFileDownloading = false;
      });
  }

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  deleteInvoiceData() {
    this.modalService.dismissAll();
    this.router.navigate([
      '/limos',
      this.limosInvoiceData.claim_number,
      this.limosInvoiceData.profile_id,
    ]);
    this.limosService.setlimosInvoiceData(null);
  }

  setDuplicateInvoice(content) {
    this.modalService.dismissAll();
    this.openModal(content, 'md');

    this.duplicateInvoices = [];
    for (let index = 0; index < this.duplicateInvoiceCount; index++) {
      this.duplicateInvoices.push({
        claimNumber: '',
        claimantName: '',
      });
    }
    this.getDuplicateInvoicePaginatedData();
  }

  onPageChangeDuplicateInvoice(event: any) {
    this.duplicateInvoicePage = event;
    this.getDuplicateInvoicePaginatedData();
  }

  onSizeChangeDuplicateInvoice(limit: number) {
    this.duplicateInvoiceLimit = limit;
    this.duplicateInvoicePage = 1;
    this.getDuplicateInvoicePaginatedData();
  }

  getDuplicateInvoicePaginatedData() {
    this.paginatedDuplicateInvoices = this.duplicateInvoices.filter(
      (invoice, index) =>
        index >= this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) &&
        index <
          this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) +
            this.duplicateInvoiceLimit
    );
    console.log(this.paginatedDuplicateInvoices);
  }

  ngOnDestroy(): void {}
}
