import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateInvoiceReq,
  DuplicateInvoice,
  LimosDataResponse,
  LimosInvoiceData,
  UpdateLimosResponseDetails,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import {
  ClientDataResponse,
  ClientCreateResponse,
} from 'src/app/models/client/client.interface';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-limos-invoice-pdf',
  templateUrl: './limos-invoice-pdf.component.html',
  styleUrls: ['./limos-invoice-pdf.component.css'],
})
export class LimosInvoicePdfComponent implements OnInit, OnDestroy {
  limosInvoiceData: LimosInvoiceData;
  isFileDownloading = false;
  showBillingEmailInput = false;
  duplicateInvoiceCount: number;
  duplicateInvoices: DuplicateInvoice[];
  paginatedDuplicateInvoices: DuplicateInvoice[];
  duplicateInvoicePage = 1;
  duplicateInvoiceLimit = 20;
  public serverDateFormat = 'yyyy-MM-dd';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private limosService: LimosService,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.limosService.limosInvoiceData.subscribe(
      (limosInvoiceData: LimosInvoiceData) => {
        console.log(limosInvoiceData);
        this.limosInvoiceData = limosInvoiceData;
      }
    );
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents.reduce(
      (acc, curr) => acc + (curr.total ?? 0),
      0
    );
    return isNaN(total) ? 0 : total;
  }

  exportToPdf() {
    this.isFileDownloading = true;
    var element = document.getElementById('invoicePage');
    var opt = {
      filename: `${this.limosInvoiceData.invoice_number}.pdf`,
      margin: 0.5,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf
      .default(element, opt)
      .then((result) => {
        console.log('print done', result);
        this.isFileDownloading = false;
        this.cd.detectChanges();
      })
      .catch((err) => {
        this.isFileDownloading = false;
      });
  }

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  deleteInvoiceData() {
    this.modalService.dismissAll();
    this.router.navigate([
      '/limos',
      this.limosInvoiceData.claim_number,
      this.limosInvoiceData.profile_id,
    ]);
    this.limosService.setlimosInvoiceData(null);
  }

  setDuplicateInvoice(content) {
    this.modalService.dismissAll();
    this.openModal(content, 'md');

    this.duplicateInvoices = [];
    for (let index = 0; index < this.duplicateInvoiceCount; index++) {
      this.duplicateInvoices.push({
        claimNumber: '',
        claimantName: '',
      });
    }
    this.getDuplicateInvoicePaginatedData();
  }

  onPageChangeDuplicateInvoice(event: any) {
    this.duplicateInvoicePage = event;
    this.getDuplicateInvoicePaginatedData();
  }

  onSizeChangeDuplicateInvoice(limit: number) {
    this.duplicateInvoiceLimit = limit;
    this.duplicateInvoicePage = 1;
    this.getDuplicateInvoicePaginatedData();
  }

  getDuplicateInvoicePaginatedData() {
    this.paginatedDuplicateInvoices = this.duplicateInvoices.filter(
      (invoice, index) =>
        index >= this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) &&
        index <
          this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) +
            this.duplicateInvoiceLimit
    );
  }

  submitDuplicateInvoiceData() {
    if (this.paginatedDuplicateInvoices?.length) {
      this.paginatedDuplicateInvoices.forEach(
        ({ claimNumber, claimantName }) => {
          this.saveInvoice(claimNumber, claimantName);
        }
      );
    }
  }

  saveInvoice(claim_number: string, claimant_name: string) {
    const {
      created_on,
      due_on,
      billing_to,
      billing_location,
      billing_contact,
      billing_email,
      profile_id,
      provider_name,
      time_spents,
    } = this.limosInvoiceData;
    const req: CreateInvoiceReq = {
      created_on: this.datePipe.transform(created_on, this.serverDateFormat),
      due_on: this.datePipe.transform(due_on, this.serverDateFormat),
      bill_to: billing_to,
      location: billing_location,
      contact: billing_contact,
      billing_email: billing_email,
      limos_case_number: profile_id,
      claim_number,
      claimant_name,
      provider_name,
      time_logs: time_spents?.length
        ? time_spents.map((time_spent) => time_spent.id)
        : [],
    };

    this.limosService
      .createInvoice(req)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  checkDuplicateInvoiceDisable(): boolean {
    return this.paginatedDuplicateInvoices.some(
      (invoice) => invoice.claimNumber == '' || invoice.claimantName == ''
    )
      ? true
      : false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
