import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import {
  ClientDataResponse,
  ClientCreateResponse,
  ClientPaginatedData,
  Client,
} from '../../models/client/client.interface';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
  clientForm: FormGroup;
  isClientFormSubmitted = false;
  clientLimit = 20;
  clientPage = 1;
  clientTotal: number;
  clients: Client[];
  @BlockUI('client-list-ui') clientListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private addmodalService: NgbModal,
    private fb: FormBuilder,
    private clientService: ClientService,
    private toastr: ToastrService
  ) {
    this.initClientForm();
  }

  ngOnInit() {
    this.getClients();
  }

  openPopup(content, size = 'md') {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  initClientForm() {
    this.clientForm = this.fb.group({
      client_name: new FormControl('', [Validators.required]),
      program_name: new FormControl(''),
      tpa: new FormControl(''),
      mga: new FormControl(''),
      email: new FormControl(''),
      main_phone: new FormControl(''),
      fax: new FormControl(''),
      client_instructions: new FormControl(''),
      invoice_instructions: new FormControl(''),
      general_notes: new FormControl(''),
      address: this.fb.group({
        location_name: new FormControl('', [Validators.required]),
        address_1: new FormControl('', [Validators.required]),
        address_2: new FormControl(''),
        address_3: new FormControl(''),
        city: new FormControl('', [Validators.required]),
        state: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        zip: new FormControl('', [Validators.required]),
      }),
    });
  }

  onSubmitClientForm() {
    if (this.clientForm.invalid) {
      return;
    }

    this.isClientFormSubmitted = true;

    this.clientService
      .createClient(this.clientForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.addmodalService.dismissAll();
          this.clientForm.reset();
          this.isClientFormSubmitted = false;
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  getClients() {
    this.clientListBlockUI.start();
    this.clientService.getClients(this.clientLimit, this.clientPage).subscribe(
      (resp: ClientDataResponse<ClientPaginatedData>) => {
        const { hits, offset, estimatedTotalHits } = resp.details;
        this.clients = hits;
        this.clientTotal = estimatedTotalHits;
        this.clientListBlockUI.stop();
      },
      (error) => {
        this.clientListBlockUI.stop();
      }
    );
  }

  onPageChangeclients(event: any) {
    console.log(event);
    this.clientPage = event;
    this.getClients();
  }

  onSizeChangeclients(limit: number) {
    this.clientLimit = limit;
    this.clientPage = 1;
    this.getClients();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
