import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

declare var $: any;

@Directive({
  selector: '[jDate]',
})
export class JqueryDatepickerDirective implements OnInit, OnDestroy {
  private element: any;
  @Input() formCtrl: FormControl;
  @Input() meta: { item: any; key: string };

  constructor(
    private el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.element = $(this.el.nativeElement);
    this.element.datepicker({
      format: 'mm/dd/yyyy',
      ...(this.formCtrl && {
        onSelect: (dateText) => {
          this.formCtrl.setValue(dateText);
          this.formCtrl.markAsDirty();
        }
      }),
      ...(this.meta && {
        onSelect: (dateText) => {
          const { item, key } = this.meta;
          item[key] = dateText;
        }
      })
    });
    this.element.prop('readonly', true);
    this.element.prop('placeholder', 'mm/dd/yyyy');
    this.element.prop('type', 'text');
    this.element.addClass('j-date');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event): void {
    if (this.element.datepicker('widget').is(':visible')) {
      this.element.datepicker('hide').blur();
    }
  }

  ngOnDestroy(): void {
    this.element.datepicker('destroy');
  }
}
