<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>{{ clientData?.client_name }}</h6>
        <div class="d-flex gap-1">
          <div>
            <button
              class="btn viewBtn btn-secondary text-white btn-sm"
              (click)="
                editDropdownMenu = false; newDropdownMenu = !newDropdownMenu
              "
            >
              New
            </button>
            <div
              class="bg-white p-2 rounded position-absolute z-index-1 dropdown-option border-grey"
              *ngIf="newDropdownMenu"
            >
              <ul>
                <li
                  class="border-bottom text-sm"
                  (click)="
                    openPopup(createContactMdl, 'xl'); newDropdownMenu = false
                  "
                >
                  New Contact
                </li>
                <li
                  class="text-sm"
                  (click)="
                    openPopup(createLocationMdl, 'xl'); newDropdownMenu = false
                  "
                >
                  New Location
                </li>
              </ul>
            </div>
          </div>
          <div>
            <button
              class="btn viewBtn btn-info text-white btn-sm"
              (click)="
                newDropdownMenu = false; editDropdownMenu = !editDropdownMenu
              "
            >
              Edit
            </button>
            <div
              class="bg-white p-2 rounded position-absolute z-index-1 dropdown-option border-grey"
              *ngIf="editDropdownMenu"
            >
              <ul>
                <li class="border-bottom text-sm">Profile</li>
                <li class="text-sm">Price List</li>
              </ul>
            </div>
          </div>
          <div>
            <button
              class="btn viewBtn btn-danger text-white btn-sm"
              (click)="newDropdownMenu = false; editDropdownMenu = false"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-12 primary-tab">
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let tab of pageTabs">
              <button
                class="nav-link text-uppercase"
                [ngClass]="{
                  'active search-spear-background': activeTab == tab
                }"
                (click)="activeTab = tab"
              >
                {{ tab }}
              </button>
            </li>
          </ul>
          <ng-container *ngIf="activeTab == 'Overview'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">General Information</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">Program Name:</div>
                          <div class="col-2">
                            {{ clientData?.program_name }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">TPA:</div>
                          <div class="col-3">{{ clientData?.tpa }}</div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">MGA:</div>
                          <div class="col-3">{{ clientData?.mga }}</div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Email:</div>
                          <div class="col-2">{{ clientData?.email }}</div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Main Phone:</div>
                          <div class="col-3">{{ clientData?.main_phone }}</div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">Fax:</div>
                          <div class="col-3">{{ clientData?.fax }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Address</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="addressInfo = !addressInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="addressInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="addressInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">Location Name:</div>
                          <div class="col-2">
                            {{ clientData?.address?.location_name }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Country:</div>
                          <div class="col-3">
                            {{ clientData?.address?.country }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Address 1:</div>
                          <div class="col-3">
                            {{ clientData?.address?.address_1 }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Address 2:</div>
                          <div class="col-2">
                            {{ clientData?.address?.address_2 }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Address 3:</div>
                          <div class="col-3">
                            {{ clientData?.address?.address_3 }}
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">City:</div>
                          <div class="col-3">
                            {{ clientData?.address?.city }}
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">State:</div>
                          <div class="col-3">
                            {{ clientData?.address?.state }}
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">Zip/Postal Code:</div>
                          <div class="col-3">
                            {{ clientData?.address?.zip }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Instructions & Notes</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="instructionNotesInfo = !instructionNotesInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="instructionNotesInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div
                    [ngClass]="
                      instructionNotesInfo ? 'collapsecard' : 'expandcard'
                    "
                  >
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">
                            Client Instructions:
                          </div>
                          <div class="col-2">
                            {{ clientData?.client_instructions }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">
                            Invoice Instructions:
                          </div>
                          <div class="col-3">
                            {{ clientData?.invoice_instructions }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">General Notes:</div>
                          <div class="col-3">
                            {{ clientData?.general_notes }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Locations'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Locations</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Number</th>
                              <th class="border-0" scope="col">Contacts</th>
                              <th class="border-0" scope="col">Last Case</th>
                            </tr>
                          </thead>

                          <ng-container
                            *ngIf="
                              clientData?.locations?.length;
                              else showNoLocation
                            "
                          >
                            <tbody>
                              <tr
                                class="bg-white"
                                *ngFor="let location of clientData?.locations"
                              >
                                <td lass="text-center">
                                  {{ location.location_name }}
                                </td>
                                <td lass="text-center">
                                  {{ location.primary_phone }}
                                </td>
                                <td lass="text-center"></td>
                              </tr>
                            </tbody>
                          </ng-container>

                          <ng-template ##showNoLocation>
                            <tbody>
                              <tr class="bg-white">
                                <td colspan="3" class="text-center">
                                  Data not available
                                </td>
                              </tr>
                            </tbody>
                          </ng-template>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Contacts'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Contacts</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Name</th>
                              <th class="border-0" scope="col">Title</th>
                              <th class="border-0" scope="col">Location</th>
                              <th class="border-0" scope="col">Email</th>
                              <th class="border-0" scope="col">Last Case</th>
                            </tr>
                          </thead>
                          <ng-container
                            *ngIf="
                              clientData?.contacts?.length;
                              else noContactFound
                            "
                          >
                            <tbody>
                              <tr
                                class="bg-white"
                                *ngFor="let contact of clientData?.contacts"
                              >
                                <td>
                                  {{
                                    contact?.first_name +
                                      " " +
                                      contact?.middle_name +
                                      " " +
                                      contact?.last_name
                                  }}
                                </td>
                                <td>{{ contact?.title }}</td>
                                <td>{{ contact?.location?.location_name }}</td>
                                <td>{{ contact?.email }}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </ng-container>
                          <ng-template #noContactFound>
                            <tbody>
                              <tr class="bg-white">
                                <td colspan="5" class="text-center">
                                  Data not available
                                </td>
                              </tr>
                            </tbody>
                          </ng-template>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Price List'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Price List</h6>
                    <div>
                      <button
                        class="btn viewBtn btn-primary text-white btn-sm mr-2"
                      >
                        Edit Custom Prices
                      </button>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Invoice Item</th>
                              <th class="border-0" scope="col">
                                Default Item Code
                              </th>
                              <th class="border-0" scope="col">
                                Default Price
                              </th>
                              <th class="border-0" scope="col">
                                Default Tax Rate
                              </th>
                              <th class="border-0" scope="col">
                                Custom Item Code
                              </th>
                              <th class="border-0" scope="col">Custom Price</th>
                              <th class="border-0" scope="col">
                                Custom Tax Rate
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td colspan="7" class="text-center">
                                Data not available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Invoice Rules'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Invoice Rules</h6>
                    <div>
                      <button
                        class="btn viewBtn btn-primary text-white btn-sm mr-2"
                      >
                        Add Invoice Rule
                      </button>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-center"
                      >
                        Data not available
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Notes'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Notes</h6>
                    <div>
                      <button
                        class="btn viewBtn btn-primary text-white btn-sm mr-2"
                      >
                        New Note
                      </button>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Date</th>
                              <th class="border-0" scope="col">Note</th>
                              <th class="border-0" scope="col">Attachment</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td colspan="3" class="text-center">
                                Data not available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'History'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">History</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Date</th>
                              <th class="border-0" scope="col">Action</th>
                              <th class="border-0" scope="col">Category</th>
                              <th class="border-0" scope="col">Details</th>
                              <th class="border-0" scope="col">Action By</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td colspan="5" class="text-center">
                                Data not available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createLocationMdl let-modal>
  <div class="modal-header">
    <h6>New Location</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-location">
    <form [formGroup]="clientLocationForm">
      <div class="row mb-2">
        <div class="col-4">
          <div class="d-flex align-items-center">
            <span for="location_name" class="text-sm">Name:</span>
            <input
              class="form-control ml-2"
              type="text"
              name="location_name"
              id="location_name"
              formControlName="location_name"
            />
          </div>
        </div>
        <div class="col-4 text">
          <div>
            <label for="is_primary" class="text-sm">Make Primary:</label>
            <input
              class="ml-2 border-grey"
              type="checkbox"
              name="is_primary"
              id="is_primary"
              formControlName="is_primary"
            />
          </div>
        </div>
      </div>

      <hr />

      <div class="row" [formGroupName]="'address'">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Address</span>
            <div class="row mt-2">
              <div class="col-5 d-flex align-items-center">
                <label for="country" class="text-sm">Country:</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control ml-2"
                  formControlName="country"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="address_1" class="text-sm">Address 1:</label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  class="form-control"
                  formControlName="address_1"
                />
              </div>

              <div class="col-4">
                <label for="address_2" class="text-sm">Address 2:</label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  class="form-control"
                  formControlName="address_2"
                />
              </div>

              <div class="col-4">
                <label for="address_3" class="text-sm">Address 3:</label>
                <input
                  type="text"
                  name="address_3"
                  id="address_3"
                  class="form-control"
                  formControlName="address_3"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="city" class="text-sm">City:</label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  class="form-control"
                  formControlName="city"
                />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                  formControlName="state"
                />
              </div>
              <div class="col-4">
                <label for="zip" class="text-sm">Zip/Postal Code:</label>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  class="form-control"
                  formControlName="zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Phone</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="primary_phone" class="text-sm"
                  >Primary Phone:</label
                >
                <input
                  type="text"
                  name="primary_phone"
                  id="primary_phone"
                  class="form-control"
                  formControlName="primary_phone"
                />
              </div>

              <div class="col-4">
                <label for="secondary_phone" class="text-sm"
                  >Secondary Phone:</label
                >
                <input
                  type="text"
                  name="secondary_phone"
                  id="secondary_phone"
                  class="form-control"
                  formControlName="secondary_phone"
                />
              </div>

              <div class="col-4">
                <label for="fax" class="text-sm">Fax:</label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  class="form-control"
                  formControlName="fax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address"
              >Location Notes & Policies</span
            >
            <div class="row mt-2">
              <div class="col-3">
                <label for="general_notes" class="text-sm"
                  >General Notes:</label
                >
                <textarea
                  type="text"
                  name="general_notes"
                  id="general_notes"
                  class="border-grey w-100"
                  formControlName="general_notes"
                ></textarea>
              </div>

              <div class="col-3">
                <label for="case_policies" class="text-sm"
                  >Case Policies:</label
                >
                <textarea
                  type="text"
                  name="case_policies"
                  id="case_policies"
                  class="border-grey w-100"
                  formControlName="case_policies"
                ></textarea>
              </div>

              <div class="col-3">
                <label for="update_policies" class="text-sm"
                  >Update Policies:</label
                >
                <textarea
                  type="text"
                  name="update_policies"
                  id="update_policies"
                  class="border-grey w-100"
                  formControlName="update_policies"
                ></textarea>
              </div>

              <div class="col-3">
                <label for="invoice_policies" class="text-sm"
                  >Invoice Policies:</label
                >
                <textarea
                  type="text"
                  name="invoice_policies"
                  id="invoice_policies"
                  class="border-grey w-100"
                  formControlName="invoice_policies"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right mt-4">
        <button
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn spear-button-background btn-sm text-white"
          (click)="saveClientLocation()"
          [disabled]="
            isClientLocationFormSubmitted || clientLocationForm.invalid
          "
        >
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #createContactMdl let-modal>
  <div class="modal-header">
    <h6>New Contact</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-contact">
    <form [formGroup]="clientContactForm">
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Name</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="first_name" class="text-sm">First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  class="form-control"
                  formControlName="first_name"
                />
              </div>

              <div class="col-4">
                <label for="middle_name" class="text-sm">Middle Name:</label>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  class="form-control"
                  formControlName="middle_name"
                />
              </div>

              <div class="col-4">
                <label for="last_name" class="text-sm">Last Name:</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  class="form-control"
                  formControlName="last_name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <div class="row">
              <div class="col-4">
                <label for="contact_title" class="text-sm">Title:</label>
                <input
                  type="text"
                  name="contact_title"
                  id="contact_title"
                  class="form-control"
                  formControlName="title"
                />
              </div>

              <div class="col-4">
                <label for="contact_role" class="text-sm">Role:</label>
                <select
                  type="text"
                  name="contact_role"
                  id="contact_role"
                  class="form-control"
                  formControlName="role"
                >
                  <option value="client user">Client User</option>
                </select>
              </div>

              <div class="col-4">
                <label for="contact_location" class="text-sm"
                  >Contact Location:</label
                >
                <select
                  type="text"
                  name="contact_location"
                  id="contact_location"
                  class="form-control"
                  formControlName="location"
                >
                  <ng-container *ngFor="let location of clientData?.locations">
                    <option [value]="location?.id ? location?.id : 1">
                      {{ location?.location_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-12">
                <span class="mr-2">Access Level:</span>
                <input
                  type="radio"
                  id="contact_disabled"
                  name="access_level"
                  value="Disabled"
                  formControlName="access_level"
                />
                <label for="contact_disabled" class="mx-2 text-sm"
                  >Disabled</label
                >

                <input
                  type="radio"
                  id="limited_access"
                  name="access_level"
                  value="Limited Access"
                  formControlName="access_level"
                />
                <label for="limited_access" class="mx-2 text-sm"
                  >Limited Access</label
                >

                <input
                  type="radio"
                  id="contact_full_location_access"
                  name="access_level"
                  value="Full Location Access"
                  formControlName="access_level"
                />
                <label for="contact_full_location_access" class="mx-2 text-sm"
                  >Full Location Access</label
                >

                <input
                  type="radio"
                  id="contact_full_access"
                  name="access_level"
                  value="Full Access"
                  formControlName="access_level"
                />
                <label for="contact_full_access" class="mx-2 text-sm"
                  >Full Access</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row" [formGroupName]="'address'">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Address</span>
            <div class="row mt-2">
              <div class="col-5 d-flex align-items-center">
                <label for="location_name" class="text-sm">Location:</label>
                <input
                  type="text"
                  name="location_name"
                  id="location_name"
                  class="form-control ml-2"
                  formControlName="location_name"
                />
              </div>

              <div class="col-5 d-flex align-items-center">
                <label for="country" class="text-sm">Country:</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control ml-2"
                  formControlName="country"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="address_1" class="text-sm">Address 1:</label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  class="form-control"
                  formControlName="address_1"
                />
              </div>

              <div class="col-4">
                <label for="address_2" class="text-sm">Address 2:</label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  class="form-control"
                  formControlName="address_2"
                />
              </div>

              <div class="col-4">
                <label for="address_3" class="text-sm">Address 3:</label>
                <input
                  type="text"
                  name="address_3"
                  id="address_3"
                  class="form-control"
                  formControlName="address_3"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="city" class="text-sm">City:</label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  class="form-control"
                  formControlName="city"
                />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                  formControlName="state"
                />
              </div>
              <div class="col-4">
                <label for="zip" class="text-sm">Zip/Postal Code:</label>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  class="form-control"
                  formControlName="zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Phone</span>
            <div class="row mt-2">
              <div class="col-3">
                <label for="office_phone" class="text-sm">Office Phone:</label>
                <input
                  type="text"
                  name="office_phone"
                  id="office_phone"
                  class="form-control"
                  formControlName="office_phone"
                />
              </div>

              <div class="col-3">
                <label for="mobile_phone" class="text-sm">Mobile Phone:</label>
                <input
                  type="text"
                  name="mobile_phone"
                  id="mobile_phone"
                  class="form-control"
                  formControlName="mobile_phone"
                />
              </div>

              <div class="col-4">
                <label for="home_phone" class="text-sm">Home Phone:</label>
                <input
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  class="form-control"
                  formControlName="home_phone"
                />
              </div>

              <div class="col-4">
                <label for="fax" class="text-sm">Fax:</label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  class="form-control"
                  formControlName="fax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address"
              >Other Information</span
            >
            <div class="row">
              <div class="col-3">
                <label for="birthday" class="text-sm">Birthday:</label>
                <input
                  type="text"
                  name="birthday"
                  id="birthday"
                  class="form-control"
                  [formCtrl]="clientContactForm.get('birthday')"
                  jDate
                />
              </div>

              <div class="col-3">
                <label for="language" class="text-sm">Language:</label>
                <select
                  type="text"
                  name="language"
                  id="language"
                  class="form-control"
                  formControlName="language"
                >
                  <option value="english">English</option>
                </select>
              </div>

              <div class="col-3">
                <label for="account_code" class="text-sm">Account Code:</label>
                <input
                  type="text"
                  name="account_code"
                  id="account_code"
                  class="form-control"
                  formControlName="account_code"
                />
              </div>

              <div class="col-3">
                <label for="salesperson" class="text-sm">Salesperson:</label>
                <select
                  type="text"
                  name="salesperson"
                  id="salesperson"
                  class="form-control"
                  formControlName="salesperson"
                >
                  <option value="none">None</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                <label for="case_manager" class="text-sm">Case Manager:</label>
                <input
                  type="text"
                  name="case_manager"
                  id="case_manager"
                  class="form-control"
                  formControlName="case_manager"
                />
              </div>

              <div class="col-3">
                <label for="tags" class="text-sm">Tags:</label>
                <input
                  type="text"
                  name="tags"
                  id="tags"
                  class="form-control"
                  formControlName="tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required"
              >Location Notes & Policies</span
            >
            <div class="row mt-2">
              <div class="col-4">
                <label for="general_notes" class="text-sm"
                  >General Notes:</label
                >
                <textarea
                  type="text"
                  name="general_notes"
                  id="general_notes"
                  class="border-grey w-100"
                  formControlName="general_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="admin_policies" class="text-sm"
                  >Admin Policies:</label
                >
                <textarea
                  type="text"
                  name="admin_policies"
                  id="admin_policies"
                  class="border-grey w-100"
                  formControlName="admin_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="case_policies" class="text-sm"
                  >Case Policies:</label
                >
                <textarea
                  type="text"
                  name="case_policies"
                  id="case_policies"
                  class="border-grey w-100"
                  formControlName="case_policies"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right mt-4">
        <button
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn spear-button-background btn-sm text-white"
          (click)="saveClientContact()"
          [disabled]="isClientContactFormSubmitted || clientContactForm.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>
