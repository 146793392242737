import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetTimeSpentResponseDetail, LimosInvoiceData } from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';

@Component({
  selector: 'app-limos-invoice',
  templateUrl: './limos-invoice.component.html',
  styleUrls: ['./limos-invoice.component.css']
})
export class LimosInvoiceComponent implements OnInit {
  termsCollapse = false;
  publicNotesCollapse = false;
  internalNotesCollapse = false;
  limosInvoiceData: LimosInvoiceData;
  public timeTypes = [
    'Initial Lien Review',
    'Lien Negotiation',
    'Client Correspondence',
    'D/A Correspondence',
    'Other',
  ].sort();

  constructor(
    private limosService:LimosService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit() {
    this.limosService.limosInvoiceData.subscribe((limosInvoiceData: LimosInvoiceData) => {
      this.limosInvoiceData = limosInvoiceData
    });
  }

  get providerNames(): string {
    return this.limosInvoiceData.time_spents.map(item => item.provider_name_business).join(',');
  }

  addLienItem() {
    this.limosInvoiceData.time_spents.push({
      id: null,
      user: '',
      provider_name_business: '',
      time_type: '',
      note: '',
      time_spent: '',
      log_date: '',
      rate: null,
      total: null,
      checked: false
    });
  }

  cancelInvoice(){
    this.location.back();
  }

  onChangeRate(timeSpent:GetTimeSpentResponseDetail){
    const totalSpent = timeSpent.rate * +((timeSpent.time_spent.split(' ')[0]));
    timeSpent.total = isNaN(totalSpent) ? 0 : totalSpent;
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents.reduce((acc, curr) => acc + (curr.total ?? 0), 0);
    return isNaN(total) ? 0 : total;;
  }

  saveInvoice() {
    this.limosService.setlimosInvoiceData(this.limosInvoiceData);
    this.router.navigate(['/limos/invoice-pdf']);
  }
}
