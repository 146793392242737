import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AppUtils {

    constructor(private router: Router){}
    public acceptNumber(event: any): any {
        const charCode = event.which ? event.which : event.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 40 &&
          charCode !== 45 &&
          charCode !== 41
        ) {
          return false;
        }
        return true;
      }
      
    public ProcessErrorResponse(toastr: any, response: any): void {
        const error = response.error;
        if (response.status === 400) {
            if (error instanceof Array) {
                error.forEach(item => {
                    toastr.error(item);
                });
            } else {
                toastr.error(error);
            }
        }
        else if (response.status === 403)
        {
            toastr.error('Unauthorize');
            this.router.navigate(['']);
        }
        else {
            //toastr.error('Internal server error.');
        }
    }

    public ProcessCustomResponse(toastr: ToastrService, error: boolean, messege: string): void {
        if (error) {
        toastr.error(messege);
        }
        else {
            toastr.success(messege);
         }
    }
}
