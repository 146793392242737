import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/models/manage-users/user.info';
import { EntityTypePatch, GetDownloadResponse, GetEntitiesResponse, GetFileData, GetHistoryTableResponse, GetPreferenceResponse, UpdateEditable, ArmorPutBody, GetPayCode, GetPayCodeDetail, PreferencesResponse, GetArmor, GetArmorDetailsList, ArmorCreate, UpdateArmor, GetArmorDetails, Preferences, PreferencesResponseDetails, UpdateArmorResponse } from 'src/app/models/provider/provider-detail-model';
import { Profile } from 'src/app/models/provider/provider.profile.model';
import { AccountService } from 'src/app/services/account.service';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ProvidertablesService } from 'src/app/services/providertables.service';
import { environment } from 'src/environments/environment';
import { EntityComponent } from '../shared/Modals/entity/entity.component';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.css'],
})
export class ProviderDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  apiURL = environment.fireStoreApiUrl;
  editStatus = true;
  activeBadge = false;
  draggablesArray: CdkDrag<any[]>[];
  left: CdkDrag<any>[];
  right: CdkDrag<any>[];
  addPrefUrl = environment.fireStoreApiUrl + '/api/v1/preference/add/';
  getPrefUrl = environment.fireStoreApiUrl + '/api/v1/preference/';
  showGuidance: boolean;
  isMerged: boolean;
  isEdited: boolean;
  mergedAt: string;
  userNow;
  dragData!: PreferencesResponse;
  updateDragData: PreferencesResponseDetails = {
    preferences: {
      uerName: '',
      leftDrag: [],
      rightDrag: [],
      stopmergeguide: false
    }
  }
  entityPostData: EntityTypePatch;
  widgets: boolean = true;
  selectedFile: File = null;
  isSuperAdmin: boolean = false;
  profileDataReady: boolean = false;
  armorReqBody!: ArmorPutBody;
  private subscription: Subscription;

  tipName: string;
  tipAddress: string;
  tipEntity: string;
  tipProfile: any;

  licensesObj: LicensesObj = [];

  WhitePap: any[] = [];
  ClearRep: any[] = [];
  Document: any[] = [];
  FinancialInterest: any[] = [];
  suggestedTableFinancial: any[] = [];
  suggestedTableOtherAssociated: any[] = [];
  suggestedAvailable: boolean;
  OtherAssociated: any[] = [];
  MergedFrom: any[] = [];

  entity_type: string;
  businesses: string[] = [];
  isPerson: boolean;
  isBusiness: boolean;
  isEntity: boolean;
  isRecord: boolean;
  isClearReport: boolean;
  isWhiteReport: boolean;
  isDocuments: boolean;

  @ViewChildren(CdkDrag) draggables: QueryList<CdkDrag>;
  @ViewChild('leftList') leftChild;
  @ViewChild('rightList') rightChild;

  userLeft: string[] = [];
  userRight: string[] = [];
  editArmorData: GetArmorDetailsList;
  deleteArmorId: number;

  customDropEvent(
    prevIndex: number,
    newIndex: number,
    prevContainer: CdkDropList<any[]>,
    newContainer: CdkDropList<any[]>,
    itemToMove: CdkDrag<any>
  ) {
    let dropEventData: CdkDragDrop<any[]> = {
      previousIndex: prevIndex,
      currentIndex: newIndex,
      previousContainer: prevContainer,
      container: newContainer,
      item: itemToMove,
      isPointerOverContainer: false,
      distance: null,
    };
    this.drop(dropEventData, false);
  }

  @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;
  closeResult = '';
  users: UserInfo[];
  subsciptions: Subscription[] = [];
  isArmorAvailable!: boolean;

  signupForm: FormGroup;
  todayDate: Date = new Date();
  profileId: string;
  profileType: string;
  profileModel: any;
  phoneNumber: string;
  faxNumber: string;
  phonePractice: string;

  //for collapse section
  tinStatus: boolean;
  ownStatus: boolean;
  NPIStatus: boolean;
  payStatus: boolean;
  mergedStatus: boolean;
  licStatus: boolean;
  alertStatus: boolean;
  assoStatus: boolean;
  secreState: boolean;
  fnpStatus: boolean;
  irsStatus: boolean;

  armoStatus: boolean;
  FinaStatus: boolean;
  AddreStatus: boolean;
  ContNumStatus: boolean;
  AssocStatus: boolean;
  WhiteStatus: any;
  ClearStatus: any;
  AssociateStatus: any;
  DocumentsStatus: any;
  TopReferringStatus: any;
  adrs: any;

  showPersonDetails: boolean = true;
  showBusinessDetails: boolean;
  showEntityDetails: boolean;
  currentActiveTab: string | number = '';
  personAddre = {
    type: 'Person',
    name: null,
    address: null,
    show: false
  };
  entityAddre = {
    type: 'Record',
    name: null,
    address: null,
    show: false
  };
  practiceAddre = {
    type: 'Business',
    name: null,
    address: null,
    show: false
  };

  ent_no = 0;

  page = 1;
  pageSize = 1;
  collectionSize = 0;

  payCodes = [
    {
      id: 0,
      code: 0,
      category: '',
      title: '',
      description: '',
      date: 0,
      status: '',
      authority: '',
      statecode: '',
      objecttion_language: '',
      narrative_reference: '',
      pay_code_rule: '',
      suspended_date: '',
    },
  ];

  public po_codes = [];
  private source_type!: string;
  public armorSet: GetPayCodeDetail[] = [];
  public armorResopone: GetArmorDetailsList[] = [];

  randNum: number;
  tinNumber: number;
  public financialEntities = [];
  public otherEntities = [];
  public armorForm: FormGroup;
  public armorFormCorrections: FormGroup;
  public armorFormObjection: FormGroup;
  public armorFormAdjuster: FormGroup;
  public armorFormStatic: FormGroup;
  public isPayCodeEditable: boolean = false;
  public isCorrectionsEditable: boolean = false;
  public isObjectionEditable: boolean = false;
  public isAdjusterEditable: boolean = false;
  public isArmor: boolean = false;
  private goodPaycode = [250];

  constructor(
    private addmodalService: NgbModal,
    private manageUsersService: ManageUsersService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private modalService: ModalService,
    private http: HttpClient,
    private currentUser: AccountService,
    private activeRoute: ActivatedRoute,
    private providerService: ProviderService,
    private router: Router,
    private providertable: ProvidertablesService,
    private formBuilder: FormBuilder
  ) {
    this.profileModel = new Profile();

    this.activeRoute.params.subscribe((param) => {
      this.profileId = param.id;
      this.profileType = param.type_id;
      this.ent_no = param.ent_no;

      this.http
        .get<GetHistoryTableResponse>(
          `${environment.fireStoreApiUrl}/api/v1/history-table/${param.id}/`
        )
        .subscribe((data) => {
          this.MergedFrom = data.details.edited_or_merged_profiles;
          this.isMerged = data.details.is_merged;
          this.isEdited = data.details.is_edited;
          this.mergedAt = data.details.created_at;
        });
    });
  }

  ngOnInit(): void {
    this.randNum = this.getRand();
    this.currentUser.getCurrentUserProfile().subscribe((data: any) => {
      this.userNow = data.details.name;
      if (this.userNow == 'investigator') {
        this.isSuperAdmin = true;
      }
    });

    this.providertable.onLoadPopulateTable();
    this.http.get<GetFileData>(this.apiURL + '/api/v1/file/all/ClearReport/').subscribe(
      (data) => {
        if (data.details && Object.keys(data.details).length > 0) {
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.ClearRep.push({
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id,
            });
          }
          this.isClearReport = true;
        } else {
          this.ClearRep = [];
          this.isClearReport = false;
        }
      }
    );
    this.http.get<GetFileData>(this.apiURL + '/api/v1/file/all/WhitePaper/').subscribe(
      (data) => {
        if (data.details && Object.keys(data.details).length > 0) {
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.WhitePap.push({
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id,
            });
          }
          this.isWhiteReport = true;
        } else {
          this.WhitePap = [];
          this.isWhiteReport = false;
        }
      }
    );
    this.http.get<GetFileData>(this.apiURL + `/api/v1/file/all/Document/?spear_id_list=${this.profileId}`).subscribe(
      (data) => {
        if (data.details && Object.keys(data.details).length > 0) {
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.Document.push({
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id,
            });
          }
          this.isDocuments = true;
        } else {
          this.Document = [];
          this.isDocuments = false;
        }
      }
    );
    this.FinancialInterest = this.providertable.FinancialInterest;
    this.OtherAssociated = this.providertable.OtherAssociated;
    this.providerService
      .getProviderProfile(this.profileId)
      .subscribe((response: Profile) => {
        if (response?.details) {
          this.blockUI.start();
          this.profileDataReady = true;
          this.profileModel = response;
          this.tinNumber = this.profileModel.details?.tax_id_number;
          if (this.profileModel.details?.provider_phone) {
            const cleanedNumberPhone = this.profileModel.details?.provider_phone
              .toString()
              .replace(/\D/g, '');
            this.phoneNumber = `(${cleanedNumberPhone.substr(
              0,
              3
            )}) ${cleanedNumberPhone.substr(3, 3)}-${cleanedNumberPhone.substr(
              6
            )}`;
          }
          if (this.profileModel.details?.provider_fax) {
            const cleanedNumberFax = this.profileModel.details?.provider_fax
              .toString()
              .replace(/\D/g, '');
            this.faxNumber = `(${cleanedNumberFax.substr(
              0,
              3
            )}) ${cleanedNumberFax.substr(3, 3)}-${cleanedNumberFax.substr(6)}`;
          }
          if (this.profileModel.details?.provider_phone_practice) {
            const cleanedNumberPractice =
              this.profileModel.details?.provider_phone_practice
                .toString()
                .replace(/\D/g, '');
            this.phonePractice = `(${cleanedNumberPractice.substr(
              0,
              3
            )}) ${cleanedNumberPractice.substr(
              3,
              3
            )}-${cleanedNumberPractice.substr(6)}`;
          }

          if (
            this.profileModel.details.provider_name_person == null ||
            this.profileModel.details.provider_name_person == ''
          ) {
            this.profileModel.details.firstname = '';
            this.profileModel.details.middlename = '';
            this.profileModel.details.lastname = '';
          } else {
            const name = this.profileModel.details.provider_name_person
              .split(' ')
              .filter((x) => x);
            this.profileModel.details.firstname = name[0];
            this.profileModel.details.middlename =
              name.length > 2 ? name[1] : '';
            this.profileModel.details.lastname =
              name.length > 2 ? name[2] : name[1];
          }
          let officeName;
          if (
            this.profileModel.details.official_first_name &&
            this.profileModel.details.official_last_name
          ) {
            officeName =
              this.profileModel.details.official_first_name +
              ' ' +
              this.profileModel.details.official_last_name;
          }
          this.personAddre.name =
            this.profileModel.details.provider_name_person ||
            officeName ||
            'No Name Available';
          this.personAddre.address =
            (this.profileModel.details?.provider_street_address_1
              ? `${this.profileModel.details?.provider_street_address_1}, `
              : '') +
            (this.profileModel.details?.provider_street_address_2
              ? `${this.profileModel.details?.provider_street_address_2}, `
              : '') +
            (this.profileModel.details?.provider_city
              ? `${this.profileModel.details?.provider_city}, `
              : '') +
            (this.profileModel.details?.provider_state
              ? `${this.profileModel.details?.provider_state}, `
              : '') +
            (this.profileModel.details?.provider_zip
              ? this.profileModel.details?.provider_zip
              : '');
          this.practiceAddre.name = this.profileModel.details
            .provider_name_business
            ? this.profileModel.details.provider_name_business
            : 'No Name Available';
          this.practiceAddre.address =
            (this.profileModel.details?.provider_street_address_1_practice
              ? `${this.profileModel.details?.provider_street_address_1_practice}, `
              : '') +
            (this.profileModel.details?.provider_street_address_2_practice
              ? `${this.profileModel.details?.provider_street_address_2_practice}, `
              : '') +
            (this.profileModel.details?.provider_city_practice
              ? `${this.profileModel.details?.provider_city_practice}, `
              : '') +
            (this.profileModel.details?.provider_state_practice
              ? `${this.profileModel.details?.provider_state_practice}, `
              : '') +
            (this.profileModel.details?.provider_zip_practice
              ? this.profileModel.details?.provider_zip_practice
              : '');
          this.entityAddre.name = this.profileModel?.details
            ?.provider_name_entity
            ? this.profileModel?.details?.provider_name_entity
            : 'No Name Available';
          this.entityAddre.address =
            (this.profileModel.details?.provider_entity_address_street
              ? `${this.profileModel.details?.provider_entity_address_street}, `
              : '') +
            (this.profileModel.details?.provider_entity_address_city
              ? `${this.profileModel.details?.provider_entity_address_city}, `
              : '') +
            (this.profileModel.details?.provider_entity_address_state
              ? `${this.profileModel.details?.provider_entity_address_state}, `
              : '') +
            (this.profileModel.details?.provider_entity_address_zip
              ? this.profileModel.details?.provider_entity_address_zip
              : '');

          if (
            this.profileModel.details.provider_alias_business != null &&
            this.profileModel.details.entity_type_code == 1
          ) {
            this.businesses =
              this.profileModel.details.provider_alias_business.split(', ');
          }

          if (this.profileModel.details.medical_licence_numbers != null) {
            let medical_license_number_obj =
              this.profileModel.details.medical_licence_numbers;
            let existingMedicalNumbersArray = Object.keys(
              medical_license_number_obj
            ).filter((key) => medical_license_number_obj[key] != '');
            let medical_license_state_obj = Object.assign(
              [],
              this.profileModel.details.medical_licence_states
            );
            existingMedicalNumbersArray.forEach((key) => {
              this.licensesObj.push({
                number: medical_license_number_obj[key],
                state:
                  this.profileModel.details.spear_id_prefix === 'SUSPALL'
                    ? 'CA'
                    : medical_license_state_obj[key],
                status: this.profileModel.details.license_status,
                type: this.profileModel.details.license_type,
              });
            });
          }

          this.payCodes[0].code = this.profileModel.details.po_code;
          this.payCodes[0].status = this.profileModel.details.pay_code_status;
          this.payCodes[0].authority = this.profileModel.details.authority;
          this.payCodes[0].statecode = this.profileModel.details.state_code;
          this.payCodes[0].objecttion_language =
            this.profileModel.details.state_bill_objection_language;
          this.payCodes[0].narrative_reference =
            this.profileModel.details.narrative_reference;
          this.payCodes[0].pay_code_rule =
            this.profileModel.details.pay_code_rule;
          this.payCodes[0].suspended_date =
            this.profileModel.details.suspended_date;

          this.assignLeftRightDrag();

          for (let i = 0; i < this.profileModel.details.entities.length; i++) {
            this.http
              .get<GetEntitiesResponse>(
                `${environment.fireStoreApiUrl}/api/v1/associations/entities/get/${this.profileModel.details.entities[i].id}`
              )
              .subscribe((resp) => {
                const ff = resp.details.associations.find(
                  (x) =>
                    x.spear_profile.spear_id ===
                    this.profileModel.details.spear_id
                );
                if (ff.association_type === 'Financial') {
                  this.financialEntities.push({
                    entity_name: resp.details.entity_name,
                    id: this.profileModel.details.entities[i].id
                  });
                } else if (ff.association_type === 'Other') {
                  this.otherEntities.push({
                    entity_name: resp.details.entity_name,
                    id: this.profileModel.details.entities[i].id
                  });
                }
              });
          }
          this.source_type = this.profileModel?.details?.spear_id_prefix;
          this._preloadArmor(this.source_type);
          this._populateArmorPageFields();
          const prov_npi = this.profileModel?.details?.provider_npi_number;
          this.isArmor = this.goodPaycode.includes(this.profileModel.details?.po_code);
          if (prov_npi) {
            this.providerService.getProvidersFilteredFromFireStore(1, 20, true, `&npi=${prov_npi}`, false).subscribe(
              (response) => {
                if (response?.details?.hits[0]?.applied_filters?.entity_type_code === 1) {
                  this.personAddre.show = true;
                  this.blockUI.stop();
                  this.isPerson = true;
                  this.currentActiveTab = 'Person';
                } else if (response?.details?.hits[0]?.applied_filters?.entity_type_code === 2) {
                  this.practiceAddre.show = true;
                  this.isBusiness = true;
                  this.currentActiveTab = 'Business';
                  this.blockUI.stop();
                }
              });
          } else {
            this.isRecord = true;
            this.entityAddre.show = true;
            this.currentActiveTab = 'Record';
            this.blockUI.stop();
          }
        }
      });
    this.armorFormStatic = this.formBuilder.group({
      armor_effective_date_of_action: new FormControl(''),
      armor_po_code: new FormControl(0),
      armor_pay_code_status: new FormControl(''),
      armor_pay_code_rule: new FormControl('')
    });
    this.armorForm = this.formBuilder.group({
      armor_po_code: new FormControl(0),
      armor_br_code: new FormControl(''),
      armor_state_code: new FormControl(''),
      armor_adj_alert_sent: new FormControl(''),
      armor_alert_date: new FormControl(''),
      armor_additional_pay_code: new FormControl(''),
      armor_effective_obj_from: new FormControl(''),
      armor_effective_obj_to: new FormControl(''),
      armor_notice_issued: new FormControl(''),
      armor_suspension_date: new FormControl('')
    });
    this.armorFormCorrections = this.formBuilder.group({
      armor_corrections: new FormControl('')
    });
    this.armorFormObjection = this.formBuilder.group({
      armor_objections: new FormControl('')
    });
    this.armorFormAdjuster = this.formBuilder.group({
      armor_adjuster: new FormControl('')
    });
  }

  ngAfterViewInit() {
    this.draggables.changes.subscribe(() => {
      const arr = this.draggables.toArray();
      this.left =
        this.userLeft.length > 0
          ? arr.filter((item) =>
            this.userLeft.includes(item.element.nativeElement.id)
          )
          : arr.slice(0, 5);
      this.right =
        this.userRight.length > 0
          ? arr.filter((item) =>
            this.userRight.includes(item.element.nativeElement.id)
          )
          : arr.slice(5);
    });
    Promise.resolve().then(() => {
      this.draggablesArray = this.draggables.toArray();
      this.left = this.draggablesArray.slice(0, 5);
      this.right = this.draggablesArray.slice(5);
      this.leftChild.data = this.left;
      this.rightChild.data = this.right;
    });
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach((x) => x.unsubscribe());
    this.providerService.ShowButtonsInProvider = false;
  }

  private loadUsers(): void {
    this.blockUI.start();
    this.manageUsersService.getUsers().subscribe(
      (data) => {
        this.users = data as UserInfo[];
        this.blockUI.stop();
      }
    );
  }









  openModal(): void {
    this.modalService.openModalForAddOrEditUser();
  }

  edit(userId: string): void {
    this.modalService.openModalForAddOrEditUser(userId);
  }
  activateOrInactive(userId: string, isActive: boolean): void {
    this.blockUI.start();
    this.manageUsersService
      .activateOrInactivateUser(userId, !isActive)
      .subscribe(
        (data) => {
          this.loadUsers();
          this.blockUI.stop();
        }
      );
  }

  closeEdit(): void {
    this.editStatus = !this.editStatus;
  }

  backtoMerge(): void {
    this.router.navigate(['/merge']);
  }

  searchMerge(npiCode: string): void {
    this.router.navigate(['/merge', { npi: npiCode }]);
  }

  togglePanel(rec: string): void {
    if (rec === 'Tin') this.tinStatus = !this.tinStatus;
    if (rec === 'Own') this.ownStatus = !this.ownStatus;
    if (rec === 'NPI') this.NPIStatus = !this.NPIStatus;
    if (rec === 'PAY') this.payStatus = !this.payStatus;
    if (rec === 'MERGED') this.mergedStatus = !this.mergedStatus;
    if (rec === 'LIC') this.licStatus = !this.licStatus;
    if (rec === 'Alerts') this.alertStatus = !this.alertStatus;
    if (rec === 'Asso') this.assoStatus = !this.assoStatus;
    if (rec === 'Secre') this.secreState = !this.secreState;
    if (rec === 'Irs') this.irsStatus = !this.irsStatus;
    if (rec === 'Fnp') this.fnpStatus = !this.fnpStatus;

    if (rec === 'Armo') this.armoStatus = !this.armoStatus;
    if (rec === 'Fina') this.FinaStatus = !this.FinaStatus;
    if (rec === 'Addre') this.AddreStatus = !this.AddreStatus;
    if (rec === 'ContNum') this.ContNumStatus = !this.ContNumStatus;
    if (rec === 'Assoc') this.AssocStatus = !this.AssocStatus;
    if (rec === 'White') this.WhiteStatus = !this.WhiteStatus;
    if (rec === 'Clear') this.ClearStatus = !this.ClearStatus;
    if (rec === 'Associate') this.AssociateStatus = !this.AssociateStatus;
    if (rec === 'Documents') this.DocumentsStatus = !this.DocumentsStatus;
    if (rec === 'TopReferring')
      this.TopReferringStatus = !this.TopReferringStatus;
  }

  getPageSymbol(current: number) {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G'][current - 1];
  }

  getRand() {
    return Math.floor(Math.random() * (9 - 0) + 1);
  }
  payCodeforAromor(paycode: number) {
    // this.payCodes.forEach((element, index) => {
    //   if (element.code === paycode) {
    //     this.page = index + 1;
    //   }
    // });
  }

  drop(event: CdkDragDrop<any[]>, callAPI: boolean = true) {
    const nodeToMove = event.item.element.nativeElement;
    const { previousContainer, container, previousIndex, currentIndex } = event;

    if (previousContainer === container) {
      moveItemInArray(container.data, previousIndex, currentIndex);

      moveWithinContainer(
        container.element.nativeElement,
        previousIndex,
        currentIndex
      );
    } else {
      transferArrayItem(
        previousContainer.data,
        container.data,
        previousIndex,
        currentIndex
      );
      transferNodeToContainer(
        nodeToMove,
        container.element.nativeElement,
        currentIndex
      );

      Promise.resolve().then(() => {
        previousContainer.removeItem(event.item);
        event.item.dropContainer = container;
        event.item._dragRef._withDropContainer(container._dropListRef);
        container.addItem(event.item);
      });
    }
    if (callAPI) {
      this.userLeft = this.left.map((item) => {
        return item.element.nativeElement.id;
      });
      this.userRight = this.right.map((item) => {
        return item.element.nativeElement.id;
      });

      this.updateDragData.preferences = {
        uerName: 'Super Admin',
        leftDrag: this.userLeft,
        rightDrag: this.userRight,
        stopmergeguide: true,
      };
      this.http.post(this.addPrefUrl, this.updateDragData).subscribe();
    }
  }
  onFileSelectedWhitePaper(event) {
    this.providertable.uploadWhitePaper(event);
    this.providertable.configObservable.subscribe((data) => {
      this.WhitePap = this.providertable.WhitePap;
    });
  }

  onFileSelectedClearReport(event) {
    this.providertable.uploadClearReport(event);
    this.providertable.configObservable.subscribe((data) => {
      this.ClearRep = this.providertable.ClearRep;
    });
  }

  onFileSelectedDocument(event) {
    this.providertable.uploadDocument(event, [this.profileId]);
    this.providertable.configObservable.subscribe((data) => {
      if (data) {
        this.Document = this.providertable.Document;
        this.isDocuments = true;
      }
    });
  }

  deleteConfirmation(uID: any, deleteFrom: any): void {
    this.modalService.openModalForConfirmation(uID, deleteFrom);

    this.providertable.configObservable.subscribe((data) => {
      if (deleteFrom == 'Clear') {
        this.ClearRep = this.providertable.ClearRep;
        if (this.ClearRep.length > 0) {
          this.isClearReport = true;
        } else {
          this.isClearReport = false;
        }
      } else if (deleteFrom == 'White') {
        this.WhitePap = this.providertable.WhitePap;
        if (this.WhitePap.length > 0) {
          this.isWhiteReport = true;
        } else {
          this.isWhiteReport = false;
        }
      } else if (deleteFrom == 'Document') {
        this.Document = this.providertable.Document;
        if (this.Document.length > 0) {
          this.isDocuments = true;
        } else {
          this.isDocuments = false;
        }
      }
    });
  }

  public deleteDocument(uid: string, spearId: string[]): void {
    this.modalService.openModalForDeleteDocument(uid, spearId);
    this.subscription = this.providertable.documentSubject.subscribe(
      (document: any) => {
        if(document.length > 0){
          this.Document = document;
          this.isDocuments = true;
        } else {
          this.isDocuments = false;
        }
      }
    );
  }

  deleteFinancial(id: any): void {
    this.modalService.openDisccociateFinancialInterestModal(id);
    this.providertable.configObservable.subscribe((data) => {
      this.FinancialInterest = [];
      this.FinancialInterest = this.providertable.FinancialInterest;
      let adrs = `${this.profileModel.details?.provider_street_address_1}, ${this.profileModel.details?.provider_street_address_2}, ${this.profileModel.details?.provider_city}, ${this.profileModel.details?.provider_state} ${this.profileModel.details?.provider_zip}`;
      this.providerService
        .getSuggestedList(
          5,
          0,
          adrs,
          this.profileModel.details.entity_type_code,
          this.profileModel.details.title_or_position
        )
        .subscribe((data) => {
          if (data || data != null) {
            for (let i = 0; i <= data.details.hits.length - 1; i++) {
              if (
                data.details.hits[i].spear_id !=
                this.profileModel.details.spear_id
              ) {
                this.suggestedTableFinancial = [];
                const result = this.FinancialInterest.find(
                  ({ firestore }) => firestore == data.details.hits[i].spear_id
                );
                if (result == undefined) {
                  this.suggestedTableFinancial.push({
                    name: data.details.hits[i].provider_name_person,
                    npi: data.details.hits[i].provider_npi_number,
                    code: data.details.hits[i].po_code,
                    id: data.details.hits[i].spear_id,
                  });
                }
              }
            }
          }
        });
    });
  }

  deleteAssociate(id: any): void {
    this.modalService.openDisccociateOtherAssociate(id);
    this.providertable.configObservable.subscribe((data) => {
      this.OtherAssociated = [];
      this.OtherAssociated = this.providertable.OtherAssociated;
      let adrs = `${this.profileModel.details?.provider_street_address_1}, ${this.profileModel.details?.provider_street_address_2}, ${this.profileModel.details?.provider_city}, ${this.profileModel.details?.provider_state} ${this.profileModel.details?.provider_zip}`;
      this.providerService
        .getSuggestedList(
          5,
          0,
          adrs,
          this.profileModel.details.entity_type_code,
          this.profileModel.details.title_or_position
        )
        .subscribe((data) => {
          if (data || data != null) {
            for (let i = 0; i <= data.details.hits.length - 1; i++) {
              if (
                data.details.hits[i].spear_id !=
                this.profileModel.details.spear_id
              ) {
                this.suggestedTableOtherAssociated = [];
                const result = this.OtherAssociated.find(
                  ({ firestore }) => firestore == data.details.hits[i].spear_id
                );
                if (result == undefined) {
                  this.suggestedTableOtherAssociated.push({
                    name: data.details.hits[i].provider_name_person,
                    npi: data.details.hits[i].provider_npi_number,
                    code: data.details.hits[i].po_code,
                    id: data.details.hits[i].spear_id,
                  });
                }
              }
            }
          }
        });
    });
  }

  chooseEntity(entityChoice: any) {
    const confirmationModal = this.addmodalService.open(EntityComponent, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    confirmationModal.componentInstance.entityChoice = entityChoice;
    confirmationModal.componentInstance.evt.subscribe(($event) => {
      if ($event) {
        if (entityChoice == 'Person') {
          this.entityPostData.entity_type_code = 1;
          let postURL = `${environment.fireStoreApiUrl}/api/v1/profile/entity/${this.profileId}/`;
          this.http.patch(postURL, this.entityPostData).subscribe(
            (data) => {
              window.location.reload();
            },
            (error) => { }
          );
        } else if (entityChoice == 'Business') {
          this.entityPostData.entity_type_code = 2;
          let postURL = `${environment.fireStoreApiUrl}/api/v1/profile/entity/${this.profileId}/`;
          this.http.patch(postURL, this.entityPostData).subscribe(
            (data) => {
              window.location.reload();
            },
            (error) => { }
          );
        }
      }
    });
  }

  download(userUID: any) {
    this.http
      .get<GetDownloadResponse>(`${environment.fireStoreApiUrl}/api/v1/file/download/${userUID}`)
      .subscribe((data: any) => {
        const a = document.createElement('a');
        a.href = data.details.signed_url;
        a.click();
      });
  }

  assignLeftRightDrag() {
    try {
      this.http.get<GetPreferenceResponse>(this.getPrefUrl).subscribe((data) => {
        if (data.details.preferences.leftDrag) {
          this.userLeft = data.details.preferences.leftDrag;
        }
        if (data.details.preferences.rightDrag) {
          this.userRight = data.details.preferences.rightDrag;
        }

        if (data.details.preferences.stopmergeguide) {
          this.showGuidance = data.details.preferences.stopmergeguide;
        } else {
          this.showGuidance = true;
        }
        this.aligntLeftRightItems();
        this.widgets = false;

        if (!data.details.preferences.leftDrag && !data.details.preferences.rightDrag) {
          this.updateDragData.preferences = {
            uerName: 'Super Admin',
            leftDrag: [
              "armor",
              "clearreports",
              "contactnumbers",
              "topreferring",
              "documents"
            ],
            rightDrag: [
              "addresses",
              "financialinterests",
              "otherassociatedproviders",
              "whitepaper",
              "associates"
            ],
            stopmergeguide: true,
          };
          this.http.post(this.addPrefUrl, this.updateDragData).subscribe(() => {
            window.location.reload();
          });
        }
      });
    } catch (error) {
        this.updateDragData.preferences = {
          uerName: 'Super Admin',
          leftDrag: [
            "armor",
            "clearreports",
            "contactnumbers",
            "topreferring",
            "documents"
          ],
          rightDrag: [
            "addresses",
            "financialinterests",
            "otherassociatedproviders",
            "whitepaper",
            "associates"
          ],
          stopmergeguide: true,
        };
        this.http.post(this.addPrefUrl, this.updateDragData).subscribe(() => {
          window.location.reload();
        });
    }
  }

  aligntLeftRightItems(): void {
    if (this.userLeft.length) {
      const leftLength = this.right.length;
      for (let i = 0; i < this.userLeft.length; i++) {
        if (
          i <= leftLength - 1 &&
          this.left[i].element.nativeElement.id === this.userLeft[i]
        )
          continue;

        let itemIndex = this.left.findIndex(
          (item) => item.element.nativeElement.id === this.userLeft[i]
        );
        if (itemIndex != -1) {
          this.customDropEvent(
            itemIndex,
            i,
            this.leftChild,
            this.leftChild,
            this.draggablesArray.find(
              (x) => x.element.nativeElement.id === this.userLeft[i]
            )
          );
          continue;
        }

        itemIndex = this.right.findIndex(
          (item) => item.element.nativeElement.id === this.userLeft[i]
        );
        if (itemIndex != -1) {
          this.customDropEvent(
            itemIndex,
            i,
            this.rightChild,
            this.leftChild,
            this.draggablesArray.find(
              (x) => x.element.nativeElement.id === this.userLeft[i]
            )
          );
          continue;
        }
      }
    }

    if (this.userRight.length) {
      const rightLength = this.right.length;
      for (let i = 0; i < this.userRight.length; i++) {
        if (
          i <= rightLength - 1 &&
          this.right[i].element.nativeElement.id === this.userRight[i]
        )
          continue;

        let itemIndex = this.right.findIndex(
          (item) => item.element.nativeElement.id === this.userRight[i]
        );
        //moving within same container
        if (itemIndex != -1) {
          this.customDropEvent(
            itemIndex,
            i,
            this.rightChild,
            this.rightChild,
            this.draggablesArray.find(
              (x) => x.element.nativeElement.id === this.userRight[i]
            )
          );
          continue;
        }

        itemIndex = this.left.findIndex(
          (item) => item.element.nativeElement.id === this.userRight[i]
        );
        //moving within different container
        if (itemIndex != -1) {
          this.customDropEvent(
            itemIndex,
            i,
            this.leftChild,
            this.rightChild,
            this.draggablesArray.find(
              (x) => x.element.nativeElement.id === this.userRight[i]
            )
          );
          continue;
        }
      }
    }
  }

  openAddFinancialInterestModal(uID: string): void {
    this.modalService.openAddFinancialInterestModal(uID);
    this.providertable.configObservable.subscribe((data) => {
      this.FinancialInterest = this.providertable.FinancialInterest;
    });
  }

  openAddOtherassociatedprovider(uID: string): void {
    this.modalService.openAddOtherassociatedprovider(uID);
    this.providertable.configObservable.subscribe((data) => {
      this.OtherAssociated = this.providertable.OtherAssociated;
    });
  }
  openAlertLang(lang: string): void {
    this.modalService.openAlertLang(lang);
  }

  showId(id: string) {
    this.tipName = '';
    this.tipAddress = '';
    this.tipEntity = '';
    this.providerService
      .getProviderProfile(id)
      .subscribe((response: Profile) => {
        this.tipProfile = response;
        this.tipName = this.tipProfile.details.provider_name_person;

        if (this.tipProfile.details.entity_type_code == 1) {
          this.tipEntity = 'Person';
        } else if (this.tipProfile.details.entity_type_code == 2) {
          this.tipEntity = 'Business';
        } else if (
          this.tipProfile.details.entity_type_code == 0 ||
          this.tipProfile.details.entity_type_code == '' ||
          this.tipProfile.details.entity_type_code == null
        ) {
          this.tipEntity = 'Entity';
        }
        this.tipAddress = `${this.tipProfile.details?.provider_street_address_1}, ${this.tipProfile.details?.provider_street_address_2}, ${this.tipProfile.details?.provider_city}, ${this.tipProfile.details?.provider_state}, ${this.tipProfile.details?.provider_state}, ${this.tipProfile.details?.provider_zip}`;
      });
  }

  addOtherAssociatedSuggested(selectedFirestore: string) {
    const result = this.OtherAssociated.find(
      ({ spear_id }) => spear_id == selectedFirestore
    );
    this.providertable.uploadOtherAssociate(selectedFirestore);
    this.providertable.configObservable.subscribe((data) => {
      this.OtherAssociated = this.providertable.OtherAssociated;
    });
    let refreshSuggested = this.suggestedTableOtherAssociated.filter(
      (item) => item.id !== selectedFirestore
    );
    this.suggestedTableOtherAssociated = refreshSuggested;
  }

  addFinancialSuggested(selectedFirestore: string) {
    const result = this.FinancialInterest.find(
      ({ firestore }) => firestore == selectedFirestore
    );
    if (result == undefined) {
      this.providertable.uploadFinancialInterest(selectedFirestore);
      this.providertable.configObservable.subscribe((data) => {
        this.FinancialInterest = this.providertable.FinancialInterest;
      });
      let refreshSuggested = this.suggestedTableFinancial.filter(
        (item) => item.id !== selectedFirestore
      );
      this.suggestedTableFinancial = refreshSuggested;
    }
  }

  public updateFNP() {
    const fnpVal = (<HTMLTextAreaElement>document.getElementById('fnp_status'))
      .value;
    const reqBody = {
      fnp_status: fnpVal,
    };
    this.http
      .put<UpdateEditable>(
        `${environment.fireStoreApiUrl}/api/v1/profile/update_editable_fields/${this.profileId}/`,
        reqBody
      )
      .subscribe((resp): void => {
        this.toastr.success('FNP status has been updated successfully');
      });
  }
  private _populateSelectedPayCode(pocode: number): void {
    const selectedArmor: GetPayCodeDetail = this.armorSet.find(ax => ax.po_code === pocode);
    if (!this.isArmor) {
      this.armorForm.get('armor_po_code').setValue(selectedArmor?.po_code);
      this.armorFormStatic.get('armor_po_code').setValue(selectedArmor?.po_code);
      this.armorForm.get('armor_state_code').setValue(selectedArmor?.state_code);
      this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.pay_code_status);
      this.armorFormStatic.get('armor_pay_code_rule').setValue(selectedArmor?.pay_code_rule);
      this.armorFormObjection.get('armor_objections').setValue(selectedArmor?.state_bill_objection_language);
      if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
        this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.license_status_description_short);
        this.armorFormObjection.get('armor_objections').setValue(selectedArmor?.license_status_description_long);
      }
    }
  }

  editArmorDetails(armor:GetArmorDetailsList){
    this.editArmorData = armor;
    this._populatePrevNextArmor(armor.po_code);
  }

  private _populatePrevNextArmor(pocode: number): void {
    const selectedArmor: GetArmorDetailsList = this.armorResopone.find(ax => ax.po_code === pocode);
    if (!this.isArmor) {
      this.armorForm.get('armor_po_code').setValue(selectedArmor?.po_code);
      this.armorFormStatic.get('armor_po_code').setValue(selectedArmor?.po_code);
      this.armorForm.get('armor_state_code').setValue(selectedArmor?.state_code);
      this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.pay_code_status);
      this.armorFormStatic.get('armor_pay_code_rule').setValue(selectedArmor?.pay_code_rule);
      selectedArmor.armor_objections = selectedArmor?.state_bill_objection_language;
      if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
        this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.license_status_description_short);
        selectedArmor.armor_objections = selectedArmor?.license_status_description_long;
      }
      this.armorFormObjection.get('armor_objections').setValue(selectedArmor.armor_objections);
      this.armorFormCorrections.get('armor_corrections').setValue(selectedArmor?.correction_needed);
      this.armorFormAdjuster.get('armor_adjuster').setValue(selectedArmor?.adjuster_poa);
    }
  }

  public selectPayCode(e: any) {
    const code = Number(e.target.value);
    this._populateSelectedPayCode(code);
  }

  private _preloadArmor(data_source: string): void {
    if (data_source) {
      this.http.get<GetPayCode>(this.apiURL + `/api/v1/profile/get_pay_code_list/?spear_id_prefix=${data_source}`).subscribe((resp): void => {
        if (resp) {
          resp.details.forEach((element): void => {
            this.po_codes.push(element.po_code);
          });
          this.armorSet = resp.details;
        }
      });
    }
  }


  private _populateArmorPageFields(editArmor = false): void {
    this.http.get<GetArmor>(`${this.apiURL}/api/v1/profile/armor/all/?limit=100&offset=1&spear_id_list=${this.profileId}`).subscribe((resp): void => {
      if (resp) {
        this.armorResopone = resp.details.hits;
        if(editArmor){
          const editArmorData = this.armorResopone.find(ax => ax.id === this.editArmorData.id);
          this.editArmorDetails(editArmorData);
        }
        // if (this.armorResopone.length > 0) {
        //   this.collectionSize = this.armorResopone.length;
        //   this.armorFormStatic.get('armor_po_code').setValue(this.armorResopone[this.page - 1]?.po_code);
        //   this.armorForm.get('armor_po_code').setValue(this.armorResopone[this.page - 1]?.po_code);
        //   this.armorForm.get('armor_state_code').setValue(this.armorResopone[this.page - 1]?.state_code);
        //   this.armorFormStatic.get('armor_pay_code_status').setValue(this.armorResopone[this.page - 1]?.pay_code_status);
        //   this.armorFormStatic.get('armor_pay_code_rule').setValue(this.armorResopone[this.page - 1]?.pay_code_rule);
        //   this.armorFormObjection.get('armor_objections').setValue(this.armorResopone[this.page - 1]?.state_bill_objection_language);
        //   if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
        //     this.armorFormStatic.get('armor_pay_code_status').setValue(this.armorResopone[this.page - 1]?.license_status_description_short);
        //     this.armorFormObjection.get('armor_objections').setValue(this.armorResopone[this.page - 1]?.license_status_description_long);
        //   }
        //   this.armorFormCorrections.get('armor_corrections').setValue(this.armorResopone[this.page - 1]?.correction_needed);
        //   this.armorFormAdjuster.get('armor_adjuster').setValue(this.armorResopone[this.page - 1]?.adjuster_poa);
        // }
      }
    });
  }

  public addArmor(): void {
    const toAddPayCode: number = Number((<HTMLSelectElement>(document.getElementById('pay_code_list'))).value);
    const selectedArmor: GetPayCodeDetail = this.armorSet.find(ax => ax.po_code === toAddPayCode);
    let reqBody;
    reqBody = {
      po_code: toAddPayCode,
      pay_code_status: selectedArmor?.pay_code_status,
      pay_code_rule: selectedArmor?.pay_code_rule,
      state_code: selectedArmor?.state_code,
      state_bill_objection_language: selectedArmor?.state_bill_objection_language
    }
    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      reqBody = {
        po_code: toAddPayCode,
        license_status_description_short: selectedArmor?.license_status_description_short,
        pay_code_rule: selectedArmor?.pay_code_rule,
        state_code: selectedArmor?.state_code,
        license_status_description_long: selectedArmor?.license_status_description_long
      }
    }
    this.http.post<ArmorCreate>(`${environment.fireStoreApiUrl}/api/v1/profile/armor/create/${this.profileId}/`, reqBody).subscribe((resp): void => {
      if (resp) {
        this._populateArmorPageFields();
        this.addmodalService.dismissAll();
        this.page = this.armorResopone.length;
      }
    });
  }

  public removeArmor(): void {
    this.http.delete(`${environment.fireStoreApiUrl}/api/v1/profile/armor/delete/${this.deleteArmorId}/`).subscribe((): void => {
      this._populateArmorPageFields();
      this.addmodalService.dismissAll();
      this.page = this.armorResopone.length;
      this.deleteArmorId = null;
    });
  }

  public closeAddArmor(): void {
    this.addmodalService.dismissAll();
  }

  public updatePayCode(): void {
    this.armorReqBody = {};
    this.armorForm.patchValue({
      armor_po_code: this.armorForm.get('armor_po_code')?.value,
      armor_state_code: this.armorForm.get('armor_state_code')?.value
    });
    this.armorFormStatic.patchValue({
      armor_pay_code_status: this.armorFormStatic.get('armor_pay_code_status')?.value,
      armor_pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value
    });
    this.armorFormCorrections.patchValue({
      armor_corrections: this.armorFormCorrections.get('armor_corrections')?.value
    });
    this.armorFormObjection.patchValue({
      armor_objections: this.armorFormObjection.get('armor_objections')?.value
    });
    this.armorFormAdjuster.patchValue({
      armor_adjuster: this.armorFormAdjuster.get('armor_adjuster')?.value
    });

    this.armorReqBody = {
      po_code: this.armorForm.get('armor_po_code')?.value,
      pay_code_status: this.armorFormStatic.get('armor_pay_code_status')?.value,
      pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value,
      state_code: this.armorForm.get('armor_state_code')?.value,
      state_bill_objection_language: this.armorFormObjection.get('armor_objections')?.value,
      correction_needed: this.armorFormCorrections.get('armor_corrections')?.value,
      adjuster_poa: this.armorFormAdjuster.get('armor_adjuster')?.value
    };

    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      this.armorReqBody = {
        po_code: this.armorForm.get('armor_po_code')?.value,
        license_status_description_short: this.armorFormStatic.get('armor_pay_code_status')?.value,
        pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value,
        state_code: this.armorForm.get('armor_state_code')?.value,
        license_status_description_long: this.armorFormObjection.get('armor_objections')?.value,
        correction_needed: this.armorFormCorrections.get('armor_corrections')?.value,
        adjuster_poa: this.armorFormAdjuster.get('armor_adjuster')?.value
      };
    }
    this.http.put<UpdateArmorResponse>(`${environment.fireStoreApiUrl}/api/v1/profile/armor/update/${this.editArmorData.id}/`,
      this.armorReqBody).subscribe((data): void => {
        this._populateArmorPageFields(true);
        this.isPayCodeEditable = false;
        this.toastr.success(data?.details?.message);
      });
  }
  public updateCorrections(): void {
    this.updatePayCode();
    this.isCorrectionsEditable = false;
  }
  public updateObjection(): void {
    this.updatePayCode();
    this.isObjectionEditable = false;
  }
  public updateAdjuster(): void {
    this.updatePayCode();
    this.isAdjusterEditable = false;
  }
  public enableArmorForm(): void {
    this.isPayCodeEditable = true;
  }

  public enableCorrectionsForm(): void {
    this.isCorrectionsEditable = true;
  }

  public enableObjectionForm(): void {
    this.isObjectionEditable = true;
  }

  public enableAdjusterForm(): void {
    this.isAdjusterEditable = true;
  }

  public onPageChange(page: number): void {
    this._populatePrevNextArmor(this.armorResopone[page - 1].po_code);
  }

  public openAddArmor(content) {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  public openDeleteArmor(content,id:number) {
    this.deleteArmorId = id;
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

}

function moveWithinContainer(container, fromIndex, toIndex) {
  if (fromIndex === toIndex) {
    return;
  }

  const nodeToMove = container.children[fromIndex];
  const targetNode = container.children[toIndex];

  if (fromIndex < toIndex) {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode.nextSibling);
  } else {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode);
  }
}

function transferNodeToContainer(node, container, toIndex) {
  if (toIndex === container.children.length) {
    container.appendChild(node);
  } else {
    const targetItem = container.children[toIndex];
    targetItem.parentNode.insertBefore(node, targetItem);
  }
}

type LicensesObj = Array<{
  number: number;
  state: string;
  status: string;
  type: string;
}>;
