<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>Contacts</h6>
        <div>
          <button
            class="btn viewBtn spear-button-background text-white btn-sm"
            (click)="openPopup(createClient, 'xl')"
          >
            Add Contact
          </button>
        </div>
      </div>
      <div class="card-body" *blockUI="'client-list-ui'">
        <table class="table spear-table-header-background">
          <caption>
            Contacts
          </caption>
          <thead>
            <tr>
              <th class="border-0" scope="col">Name</th>
              <th class="border-0" scope="col">Title</th>
              <th class="border-0" scope="col">Client</th>
              <th class="border-0" scope="col">Email</th>
              <th class="border-0" scope="col">Office Phone</th>
              <th class="border-0" scope="col">Cell Phone</th>
              <th class="border-0" scope="col">Location Address</th>
            </tr>
          </thead>
          <!-- <tbody *ngIf="clients?.length">
            <ng-container *ngFor="let client of clients">
              <tr class="bg-white">
                <td>
                  <a
                    [routerLink]="['/client', client.id]"
                    target="_blank"
                    class="cursor-pointer primary-text font-weight-semibold"
                    >{{ client.client_name }}</a
                  >
                </td>
                <td>{{ client.address.location_name }}</td>
                <td>{{ client.contacts }}</td>
                <td>{{ client.affiliates }}</td>
                <td>{{ client.program_name }}</td>
                <td>{{ client.tpa }}</td>
                <td>{{ client.mga }}</td>
              </tr>
            </ng-container>
          </tbody> -->
          <tbody *ngIf="true">
            <tr class="bg-white">
              <td colspan="7" class="text-center">Data not available</td>
            </tr>
          </tbody>
        </table>
        <div
          class="px-0 py-0 paginationWrapper d-flex"
          *ngIf="!(!clients || clients.length === 0)"
        >
          <ngb-pagination
            [(collectionSize)]="clientTotal"
            [(page)]="clientPage"
            [pageSize]="clientLimit"
            [maxSize]="10"
            [boundaryLinks]="true"
            (pageChange)="onPageChangeclients($event)"
          >
          </ngb-pagination>
          <div class="selected d-flex align-items-center">
            <select
              (change)="onSizeChangeclients(clientLimit)"
              class="custom-select table-select h-100"
              [(ngModel)]="clientLimit"
            >
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="75">75</option>
              <option [ngValue]="100">100</option>
            </select>
            <p class="mb-0 ml-2 text-nowrap">
              Showing {{ clientPage * clientLimit - clientLimit + 1 }}
              -
              {{
                clientPage * clientLimit > clientTotal
                  ? clientTotal
                  : clientPage * clientLimit
              }}
              of {{ clientTotal }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createClient let-modal>
  <div class="modal-header">
    <h6>Add Client</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-client text-sm">
    <form [formGroup]="clientForm">
      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 required" for="client_name"
              >Client Name</span
            >
            <input
              type="text"
              name="client_name"
              id="client_name"
              class="form-control"
              formControlName="client_name"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="program_name"
              >Program Name</span
            >
            <input
              type="text"
              name="program_name"
              id="program_name"
              class="form-control"
              formControlName="program_name"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="tpa">TPA</span>
            <input
              type="text"
              name="tpa"
              id="tpa"
              class="form-control"
              formControlName="tpa"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="program_name">MGA</span>
            <input
              type="text"
              name="mga"
              id="mga"
              class="form-control"
              formControlName="mga"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3" [formGroupName]="'address'">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Address</span>
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  name="location_name"
                  id="location_name"
                  class="form-control"
                  placeholder="Location Name"
                  formControlName="location_name"
                />
              </div>

              <div class="col-6">
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control"
                  placeholder="Country"
                  formControlName="country"
                />
              </div>
            </div>
            <input
              type="text"
              name="address_1"
              id="address_1"
              class="form-control"
              placeholder="Address 1"
              formControlName="address_1"
            />
            <input
              type="text"
              name="address_2"
              id="address_2"
              class="form-control"
              placeholder="Address 2"
              formControlName="address_2"
            />
            <input
              type="text"
              name="address_3"
              id="address_3"
              class="form-control"
              placeholder="Address 3"
              formControlName="address_3"
            />
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  name="city"
                  id="city"
                  class="form-control"
                  placeholder="City"
                  formControlName="city"
                />
              </div>
              <div class="col-4">
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                  placeholder="State"
                  formControlName="state"
                />
              </div>
              <div class="col-4">
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  class="form-control"
                  placeholder="Zip/Postal Code"
                  formControlName="zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="email">Email</span>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              formControlName="email"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="main_phone">Main Phone</span>
            <input
              type="text"
              name="main_phone"
              id="main_phone"
              class="form-control"
              formControlName="main_phone"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="fax">FAX</span>
            <input
              type="text"
              name="fax"
              id="fax"
              class="form-control"
              formControlName="fax"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="fax">Client Instructions</span>
            <textarea
              type="text"
              name="client_instructions"
              id="client_instructions"
              class="form-control"
              formControlName="client_instructions"
            ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="fax">Invoice Instructions</span>
            <textarea
              type="text"
              name="invoice_instructions"
              id="invoice_instructions"
              class="form-control"
              formControlName="invoice_instructions"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="general_notes"
              >General Notes</span
            >
            <textarea
              type="text"
              name="general_notes"
              id="general_notes"
              class="w-100 border-grey"
              rows="5"
              formControlName="general_notes"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="addmodalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="onSubmitClientForm()"
            [disabled]="clientForm.invalid || isClientFormSubmitted"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
