import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BillReviewAndPayment,
  BillReviewAndPaymentData,
  BillReviewAndPaymentResponse,
  ClaimantTable,
  GetLienClaimant,
  GetLienRepresantative,
  GetLiens,
  GetLiensResolution,
  GetLimosDocument,
  GetNotepad,
  LienRepresentative,
  LienResolution,
  LiensTable,
  LimosDataResponse,
  LimosReportCSVExportResponse,
  NoteTable,
  SimilarClaim,
  SimilarClaimRes,
  TaskStatus,
  UpdateLimosData,
  UpdateLimosResponse,
  UpdateLimosResponseDetails,
  UpdateNotepadData,
  UpdateRepresentativeData,
  UpdateResolutionData,
  UserSearchResult,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { ProviderService } from 'src/app/services/provider.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app/claimant-summary',
  templateUrl: './claimant-summary.component.html',
  styleUrls: ['./claimant-summary.component.css'],
})
export class ClaimantSummaryComponent implements OnInit, OnDestroy {
  private _claimNumber: string;
  private _deleteFileId: number;
  private _deleteRepId: number;
  private _deleteResId: number;
  private _claimantId: string;
  private _subsciptions: Subscription[] = [];
  private _noteTableRowState = {
    date: '',
    notetype: '',
    note: '',
  };
  private _lien_resolution_state = {
    resolved_date: null,
    resolved_amount: null,
    who_resolved: null,
    resolved_how: null,
    argus_directed: null,
  };
  private _lien_representative_state = {
    representative_name: '',
    title: '',
    company: '',
    phone: '',
    email: '',
    id: null,
  };
  private userSerchResult: UserSearchResult;
  private _addNoteFormValueChangeSubscription!: Subscription;
  private _resWhoRslvdId: number;
  private _resResById: number;
  private _getresEditWhoRslvdId: number;
  private _getresEditResById: number;
  @ViewChild('billReviewTable', { static: false }) billReviewTable: ElementRef;
  @ViewChild(' lienResTable', { static: false }) lienResTable: ElementRef;
  public claimantTableForm: FormGroup;
  @BlockUI('manage-users-blockUI') public blockUI: NgBlockUI;
  public isPrimaryTableEditable: boolean = false;
  public claimantTable: ClaimantTable;
  public contentEditableLien: boolean = false;
  public contentEditableNote: boolean = false;
  public textAreaCorrectionNeeded: FormControl = new FormControl({
    value: '',
    disabled: true,
  });
  public textAreaSRBillObjLng: FormControl = new FormControl({
    value: '',
    disabled: true,
  });
  public isTextAreaCorrectionNeeded: boolean = false;
  public isTextAreaSRBillObjLng: boolean = false;
  public today: Date = new Date();
  public filesTable = [];
  public liensTable: LiensTable = {} as LiensTable;
  public noteTable: NoteTable[] = [];
  public serverDateFormat = 'yyyy-MM-dd';
  public clientDateFormat = 'MM/dd/yyyy';

  public noteType = [
    {
      value: '',
      label: 'Select Note Type',
    },
    {
      value: 'Lien Negotiation',
      label: 'Lien Negotiation',
    },
    {
      value: 'Client Correspondence',
      label: 'Client Correspondence',
    },
    {
      value: 'SIU Clerical',
      label: 'SIU Clerical',
    },
    {
      value: 'D/A Correspondence',
      label: 'D/A Correspondence',
    },
    {
      value: 'Analysis/Authority Request',
      label: 'Analysis/Authority Request',
    },
    {
      value: 'Authority Granted',
      label: 'Authority Granted',
    },
  ];

  public lien_representative: LienRepresentative[] = [];

  public lien_resolution: LienResolution[] = [];

  public bill_review: BillReviewAndPaymentData = {} as BillReviewAndPaymentData;

  public similar_claims : SimilarClaim[] = [];
  public addNoteForm: FormGroup;
  public isNoteValid: boolean = false;
  public preloader1 = false;
  public preloader2 = false;
  public limosId: number;
  public activeTab = 'overview';
  public limosStatus = new FormControl();
  public wcabVenues = [
    'Anaheim - AHM',
    'Bakersfield - BAK',
    'Eureka - EUR',
    'Fresno - FRE',
    'Lodi - LOD',
    'Long Beach - LBO',
    'Los Angeles - LAO',
    'Marina del Rey - MDR',
    'Oakland - OAK',
    'Oxnard -OXN',
    'Pomona - POM',
    'Redding - RDG',
    'Riverside - RIV',
    'Sacramento - SAC',
    'Salinas - SAL',
    'San Bernardino - SBR',
    'San Diego - SDO',
    'San Francisco - SFO',
    'San Jose - SJO',
    'San Luis Obispo - SLO',
    'Santa Ana - ANA',
    'Santa Barbara - SBA',
    'Santa Rosa - SRO',
    'Van Nuys - VNO'
  ]
  billReviewAndPayments: BillReviewAndPayment[];
  @BlockUI('bill-review-payment-block-ui') brPaymentBlockUI: NgBlockUI;
  @BlockUI('similar-claims-block-ui') similarClaimBlockUI: NgBlockUI;
  public accessGroups = [
    'Admin',
    'Internal',
    'Investigative',
    'Public',
    'Vendor Management',
    'Vendors'
  ];
  public selectedNoteFiles: any[];
  public selectedNoteTags: string[] = [];
  public createExpenseEntry = false;

  private randomColor = '';
  private columnsForColor = '';
  public noteBodyeditor = '';
  public similarClaimsLimit = 20;
  public similarClaimsPage = 1;
  public similarClaimsTotal:number;
  public billReviewAndPaymentsLimit = 20;
  public billReviewAndPaymentsPage = 1;
  public billReviewAndPaymentsTotal:number;
  lienClaimantTin: string;
  private similarClaimsDataLoaded = false;
  public similarClaimExportLoader = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _http: HttpClient,
    private _fb: FormBuilder,
    private _datePipe: DatePipe,
    private addModal: NgbModal,
    private _providerService: ProviderService,
    private toastr: ToastrService,
    private limosService: LimosService,
  ) {}

  ngOnDestroy(): void {
    this._subsciptions.forEach((x) => x.unsubscribe());
  }

  ngOnInit(): void {
    this.similarClaimBlockUI.start();
    this._activatedRoute.params.subscribe((param) => {
      this.blockUI.start();
      this._claimantId = param.claimant_id;
      this.limosId = param.limos_id;
      this._http
        .get<GetLienClaimant>(
          `${environment.fireStoreApiUrl}/api/v1/liens/lien-claimant/get/${this._claimantId}/`
        )
        .subscribe((claimantData) => {
          if (claimantData.details) {
            let data = claimantData.details;
            this._claimNumber = data.wc_claim_number;
            this.claimantTable = {
              id: data.id,
              created_at: data.created_at,
              modified_at: data.modified_at,
              wc_claim_number: data.wc_claim_number,
              provider_name_business: data.provider_name_business,
              provider_npi_number: data.provider_npi_number,
              lien_amount: data.lien_amount,
              tax_id_number: data.tax_id_number,
              lien_type: data.lien_type,
              lien_request_date: data.lien_request_date,
              lien_created: data.lien_created,
              lien_requested_amount: data.lien_requested_amount,
              lien_resolved_date: data.lien_resolved_date,
              lien_resolved_amount: data.lien_resolved_amount,
              file_status:data.file_status
            };
            this.limosStatus.setValue(data.file_status);
            this._loadLienProfile();
            this.getSimilarClaims();
            this._http
              .get<GetLimosDocument>(
                `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/all/?claim_number=${data.wc_claim_number}`
              )
              .subscribe((resp) => {
                if (resp.details.length > 0) {
                  resp.details.forEach((el) => {
                    this.filesTable.push({
                      file_name: el.file_name,
                      description: el.description,
                      id: el.id,
                    });
                  });
                }
              });
            this._http
              .get<any>(
                `${environment.fireStoreApiUrl}/api/v1/liens/get_adj_number/${data.wc_claim_number}`
              )
              .subscribe((resp): void => {
                if (resp) {
                  this.claimantTable.adj = resp?.details?.adj_number;
                }
              });
            this._loadLienRep();
            this._loadLienRes();
          }
        });
    });
    this.addNoteForm = this._fb.group({
      noteDate: [''],
      noteType: [''],
      note: [''],
    });
    this.claimantTableForm = this._fb.group({
      siu_pay_code: new FormControl(),
      date_siu_objection: new FormControl(),
      objection_from: new FormControl(),
      objection_to: new FormControl(),
      siu_objection_removed: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      tot_billed: new FormControl(),
      tot_fee_schdl: new FormControl(),
      tot_paid: new FormControl(),
    });
    const formatDate = this._datePipe.transform(this.today, 'MM/dd/yyyy');
    const dd = formatDate;
    this.addNoteForm.get('noteDate').setValue(dd);
    this._addNoteFormValueChangeSubscription =
      this.addNoteForm.valueChanges.subscribe(() => {
        this.isNoteValid = Object.keys(this.addNoteForm.controls).every(
          (formKey) => this.addNoteForm.controls[formKey].value
        );
      });
  }

  private _populateNoteTable(): void {
    this._http
      .get<GetNotepad>(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-notepad/all/?claim_number=${this._claimNumber}`
      )
      .subscribe((resp) => {
        if (resp.details.length > 0) {
          this.noteTable = [];
          for (let i = 0; i < resp.details.length; i++) {
            this.noteTable.push({
              id: resp.details[i].id,
              created_at: resp.details[i].created_at,
              modified_at: resp.details[i].modified_at,
              wc_claim_number: resp.details[i].wc_claim_number,
              note_type: resp.details[i].note_type,
              note: resp.details[i].note,
              log_date: resp.details[i].log_date,
            });
          }
        }
      });
  }

  private _loadLienProfile() {
    this._http
      .get<GetLiens>(
        `${environment.fireStoreApiUrl}/api/v1/liens/${this._claimNumber}/${this.limosId}/`
      )
      .subscribe((resp) => {
        if (resp.details) {
          let data = resp.details;
          const reff_npi =
            data?.liens_profile?.bill_review_provider_referring_npi;
          const liensClaimantId = data.lien_claimants.find(
            (x) => x.id === Number(this._claimantId)
          );
          this.lienClaimantTin = liensClaimantId?.tax_id_number;
          this.getBillReviewAndPaymentData();
          if(reff_npi){
            this._providerService
            .getProvidersFilteredFromFireStore(
              1,
              5,
              true,
              `&npi=${reff_npi}`,
              false
            )
            .subscribe((response) => {
              if (response.details.hits.length > 0) {
                const found_physician = response.details.hits.find(
                  (x) => x.spear_id_prefix === 'BRS'
                );
                this.bill_review.bill_review_bill_icd_diagnosis_01 =
                  data?.liens_profile?.bill_review_bill_icd_diagnosis_01;
                this.bill_review.bill_review_bill_icd_diagnosis_02 =
                  data?.liens_profile?.bill_review_bill_icd_diagnosis_02;
                this.bill_review.bill_review_bill_icd_diagnosis_03 =
                  data?.liens_profile?.bill_review_bill_icd_diagnosis_03;
                this.bill_review.bill_review_bill_icd_diagnosis_04 =
                  data?.liens_profile?.bill_review_bill_icd_diagnosis_04;
                this.bill_review.bill_review_bill_icd_procedure_01 =
                  data?.liens_profile?.bill_review_bill_icd_procedure_01;
                this.bill_review.bill_review_bill_icd_procedure_02 =
                  data?.liens_profile?.bill_review_bill_icd_procedure_02;
                this.bill_review.bill_review_br_complete_date =
                  data?.liens_profile?.bill_review_br_complete_date;
                this.bill_review.bill_review_physician_rendering_name =
                  data?.liens_profile?.bill_review_physician_rendering_name;
                this.bill_review.bill_review_provider_referring_npi =
                  found_physician.bill_review_physician_rendering_name;
                this.bill_review.bill_review_service_code_description =
                  data?.liens_profile?.bill_review_service_code_description;
                this.bill_review.bill_review_service_code_value =
                  data?.liens_profile?.bill_review_service_code_value;
                this.bill_review.bill_review_service_from_date =
                  data?.liens_profile?.bill_review_service_from_date;
                this.bill_review.bill_review_service_through_date =
                  data?.liens_profile?.bill_review_service_through_date;
                this.bill_review.bill_review_total_br_allowance =
                  data?.liens_profile?.bill_review_total_br_allowance;
                this.bill_review.bill_review_total_charges =
                  data?.liens_profile?.bill_review_total_charges;
                this.bill_review.provider_name_business =
                  data?.liens_profile?.provider_name_business;
              }
            });
          }

          this.claimantTable.tin_number = liensClaimantId?.tax_id_number;
          this.claimantTable.injured_worker_full_name =
            data.liens_profile?.injured_worker_last_first;
          this.claimantTable.client =
            data.liens_profile?.bill_review_client_name;
          this.claimantTable.admin =data.liens_profile?.claim_administrator_1 || data.liens_profile?.claim_administrator_2;
          this.claimantTable.carrier_name =
            data.liens_profile?.bill_review_carrier_name;
          this.claimantTable.claim_status = data.liens_profile?.wc_claim_status;
          this.claimantTable.tot_bill_rev_all =
            data.liens_profile?.bill_review_total_allowance;
          this.claimantTable.claim_adj =
            data.liens_profile?.bill_review_adjuster_name;
          this.claimantTable.siu_pay_code = data.limos_data?.siu_pay_code;
          this.claimantTable.date_siu_objection =
            data.limos_data?.date_of_siu_objection;
          this.claimantTable.objection_from = data.limos_data?.objection_from;
          this.claimantTable.objection_to = data.limos_data?.objection_to;
          this.claimantTable.siu_objection_removed =
            data.limos_data?.siu_objection_removed;
          this.claimantTable.phone = data.limos_data?.bill_review_phone;
          this.claimantTable.email = data.limos_data?.bill_review_email;
          this.claimantTable.tot_billed = data.limos_data?.total_billed;
          this.claimantTable.tot_fee_schdl =
            data.limos_data?.total_fee_schedule;
          this.claimantTable.tot_paid = data.limos_data?.total_paid;
          this.claimantTable.correction_needed =
            data.limos_data?.correction_needed;
          this.claimantTable.bill_review_objection_language =
            data.limos_data?.bill_review_objection_language;
          this.liensTable.lien_request_date =
            liensClaimantId?.lien_request_date;
          this.liensTable.lien_requested_amount =
            liensClaimantId?.lien_requested_amount;
          this.liensTable.eams_lien_filed_date =
          liensClaimantId?.lien_created;
          this.liensTable.lien_amount = liensClaimantId?.lien_amount;
          this.liensTable.bill_review_allowance =
            data.liens_profile?.bill_review_total_allowance;
          this.liensTable.dates_of_service_from =
            data.limos_data?.dates_of_service_from;
          this.liensTable.dates_of_service_to =
            data.limos_data?.dates_of_service_to;
          this.liensTable.authority_requested =
          liensClaimantId?.authority_requested;
          this.liensTable.analysis_date =
            data.limos_data?.analysis_date;
          this.liensTable.referring_physician =
            data.liens_profile?.bill_review_provider_referring_npi;
          this.liensTable.wcab_venue =
            data.limos_data?.wcab_venue;
          this.liensTable.wcab_judge = data.limos_data?.wcab_judge;
          this.liensTable.lien_hearing_date = data.limos_data?.lien_hearing_date;
          this.liensTable.lien_hearing_time = data.limos_data?.lien_hearing_time;
          this.liensTable.offer_by_rep_to_resolve =
            data.limos_data?.offer_by_rep_to_resolve;
          this.liensTable.assignment = data.limos_data?.assignment;
          this.liensTable.assignement_date = data.limos_data?.assignement_date;
          this.liensTable.assignment_on_file =
            data.limos_data?.assignment_on_file;
        }
        this.textAreaCorrectionNeeded.setValue(
          this.claimantTable.correction_needed || 'Not Available'
        );
        this.textAreaSRBillObjLng.setValue(
          this.claimantTable.bill_review_objection_language || 'Not Available'
        );
        this._populateNoteTable();
      });
  }

  private _loadLienRep(): void {
    this._http
      .get<GetLienRepresantative>(
        `${environment.fireStoreApiUrl}/api/v1/liens/representative/all/?claim_number=${this._claimNumber}`
      )
      .subscribe((resp) => {
        if (resp.details.length > 0) {
          resp.details.forEach((el) => {
            this.lien_representative.push({
              representative_name: el.representative_name,
              title: el.title,
              company: el.company,
              phone: el.phone,
              email: el.email,
              id: el.id,
            });
          });
        }
      });
  }

  private _loadLienRes(): void {
    this._http
      .get<GetLiensResolution>(
        `${environment.fireStoreApiUrl}/api/v1/liens/resolution/all/?claim_number=${this._claimNumber}&tax_id_number=${this.claimantTable.tax_id_number}`
      )
      .subscribe((resp) => {
        if (resp.details.length > 0) {
          this.lien_resolution = [];
          resp.details.forEach((el) => {
            this.lien_resolution.push({
              resolved_date: el.resolved_date,
              resolved_amount: el.resolved_amount,
              who_resolved:
                el.who_resolved,
              resolved_how: el.resolved_how,
              argus_directed: el.argus_directed,
              id: el.id,
            });
          });
        }
      });
  }

  backToSearch(): void {
    this._router.navigate(['search']);
  }
  makeEditableLien() {
    this.contentEditableLien = true;
  }

  private toServerDateFormat(dateEleId: string) {
    const date =  (
      document.getElementById(dateEleId) as HTMLInputElement
    ).value;
    return date ? this._datePipe.transform(date, this.serverDateFormat) : null;
  }

  saveLien() {
    this.contentEditableLien = false;
    const formData = {
      assignement_date: this.toServerDateFormat('assignment_date'),
      assignment_on_file: (
        document.getElementById('assignment_on_file') as HTMLInputElement
      ).value,
      assignment: (document.getElementById('assignment') as HTMLInputElement)
        .value,
      dates_of_service_from: this.toServerDateFormat('dates_of_service_from'),
      dates_of_service_to: this.toServerDateFormat('dates_of_service_to'),
      analysis_date: this.toServerDateFormat('analysis_date'),
      lien_hearing_date: this.toServerDateFormat('lien_hearing_date'),
      lien_hearing_time: (document.getElementById('lien_hearing_time') as HTMLInputElement)
        .value,
      wcab_venue: (document.getElementById('wcab_venue') as HTMLInputElement)
        .value,
      wcab_judge: (document.getElementById('wcab_judge') as HTMLInputElement)
        .value,
    };
    const initialData = {
      assignement_date: this.liensTable?.assignement_date,
      assignment_on_file: this.liensTable?.assignment_on_file,
      assignment: this.liensTable?.assignment,
      dates_of_service_from: this.liensTable?.dates_of_service_from,
      dates_of_service_to: this.liensTable?.dates_of_service_to,
      analysis_date: this.liensTable?.analysis_date,
      lien_hearing_date: this.liensTable?.lien_hearing_date,
      lien_hearing_time: this.liensTable?.lien_hearing_time,
      wcab_venue: this.liensTable?.wcab_venue,
      wcab_judge: this.liensTable?.wcab_judge,
    };
    const updatedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        updatedData[key] = formData[key];
      }
    }
    if (Object.keys(updatedData).length === 0) {
      this.contentEditableLien = false;
    } else {
      this._http
        .put<UpdateLimosResponse>(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this._claimNumber}/`,
          updatedData
        )
        .subscribe((resp) => {
          if (resp) {
            this.contentEditableLien = false;
            this._loadLienProfile();
            this.toastr.success(resp?.details.message);
          }
        });
    }
  }

  editNote(id: string) {
    document.getElementById(`editNote-${id}`).hidden = true;
    document.getElementById(`saveNote-${id}`).hidden = false;
    document.getElementById(`cancelNote-${id}`).hidden = false;
    document.getElementById(`dateNote-${id}`).removeAttribute('disabled');
    document.getElementById(`notetypeNote-${id}`).removeAttribute('disabled');
    document.getElementById(`noteNote-${id}`).removeAttribute('disabled');
    this._noteTableRowState.date = (<HTMLInputElement>(
      document.getElementById(`dateNote-${id}`)
    )).value;
    this._noteTableRowState.notetype = (<HTMLInputElement>(
      document.getElementById(`notetypeNote-${id}`)
    )).value;
    this._noteTableRowState.note = (<HTMLInputElement>(
      document.getElementById(`noteNote-${id}`)
    )).value;
    let editBut = document.querySelectorAll('.editNote');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }
  saveNote(id: string) {
    document.getElementById(`editNote-${id}`).hidden = false;
    document.getElementById(`saveNote-${id}`).hidden = true;
    document.getElementById(`cancelNote-${id}`).hidden = true;
    document
      .getElementById(`dateNote-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`notetypeNote-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`noteNote-${id}`)
      .setAttribute('disabled', 'disabled');
    let date = (<HTMLInputElement>document.getElementById(`dateNote-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`dateNote-${id}`)).value
      : null;
    let type = (<HTMLInputElement>document.getElementById(`notetypeNote-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`notetypeNote-${id}`)).value
      : null;
    let note = (<HTMLInputElement>document.getElementById(`noteNote-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`noteNote-${id}`)).value
      : null;
    let reqBody = {
      note_type: type,
      note: note,
      log_date: date ? this._datePipe.transform(date, this.serverDateFormat) : null,
    };
    if (!this._deepEqual(reqBody, this._noteTableRowState)) {
      this._http
        .put<UpdateNotepadData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/limos-notepad/update/${id}/`,
          reqBody
        )
        .subscribe((resp) => {
          if (resp) {
            this.noteTable = [];
            this._populateNoteTable();
          }
        });
    }
    let editBut = document.querySelectorAll('.editNote');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  cancelNote(id: string) {
    document.getElementById(`editNote-${id}`).hidden = false;
    document.getElementById(`saveNote-${id}`).hidden = true;
    document.getElementById(`cancelNote-${id}`).hidden = true;
    document
      .getElementById(`dateNote-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`notetypeNote-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`noteNote-${id}`)
      .setAttribute('disabled', 'disabled');
    (<HTMLInputElement>document.getElementById(`dateNote-${id}`)).value =
      this._noteTableRowState.date;
    (<HTMLInputElement>document.getElementById(`notetypeNote-${id}`)).value =
      this._noteTableRowState.notetype;
    (<HTMLInputElement>document.getElementById(`noteNote-${id}`)).value =
      this._noteTableRowState.note;
    let editBut = document.querySelectorAll('.editNote');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }

  openAddNoteModal(content, size = 'md'){
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }


  addNoteOld(): void {
    const formatDate = this._datePipe.transform(this.today, 'yyyy-MM-dd');
    const body = {
      note_type: this.addNoteForm.get('noteType').value,
      note: this.addNoteForm.get('note').value,
      log_date: formatDate,
    };
    this._http
      .post<UpdateNotepadData>(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-notepad/create/${this.claimantTable.wc_claim_number}/`,
        body
      )
      .subscribe((resp) => {
        if (resp) {
          this.noteTable = [];
          this._populateNoteTable();
          this.addNoteForm.get('note').reset();
          this.addNoteForm.get('noteType').reset();
          this.isNoteValid = false;
        } else {
          return;
        }
      });
  }
  editRep(id: string) {
    document.getElementById(`editRep-${id}`).hidden = true;
    document.getElementById(`saveRep-${id}`).hidden = false;
    document.getElementById(`cancelRep-${id}`).hidden = false;
    document.getElementById(`repName-${id}`).removeAttribute('disabled');
    document.getElementById(`repTitle-${id}`).removeAttribute('disabled');
    document.getElementById(`repCompany-${id}`).removeAttribute('disabled');
    document.getElementById(`repPhone-${id}`).removeAttribute('disabled');
    document.getElementById(`repEmail-${id}`).removeAttribute('disabled');
    this._lien_representative_state.representative_name = (<HTMLInputElement>(
      document.getElementById(`repName-${id}`)
    )).value;
    this._lien_representative_state.title = (<HTMLInputElement>(
      document.getElementById(`repTitle-${id}`)
    )).value;
    this._lien_representative_state.company = (<HTMLInputElement>(
      document.getElementById(`repCompany-${id}`)
    )).value;
    this._lien_representative_state.phone = (<HTMLInputElement>(
      document.getElementById(`repPhone-${id}`)
    )).value;
    this._lien_representative_state.email = (<HTMLInputElement>(
      document.getElementById(`repEmail-${id}`)
    )).value;
    let editBut = document.querySelectorAll('.editRep');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }
  saveRep(id: string) {
    document.getElementById(`editRep-${id}`).hidden = false;
    document.getElementById(`saveRep-${id}`).hidden = true;
    document.getElementById(`cancelRep-${id}`).hidden = true;
    document
      .getElementById(`repName-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repTitle-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repCompany-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repPhone-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repEmail-${id}`)
      .setAttribute('disabled', 'disabled');
    let name = (<HTMLInputElement>document.getElementById(`repName-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`repName-${id}`)).value
      : null;
    let title = (<HTMLInputElement>document.getElementById(`repTitle-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`repTitle-${id}`)).value
      : null;
    let company = (<HTMLInputElement>(
      document.getElementById(`repCompany-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`repCompany-${id}`)).value
      : null;
    let phone = (<HTMLInputElement>document.getElementById(`repPhone-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`repPhone-${id}`)).value
      : null;
    let email = (<HTMLInputElement>document.getElementById(`repEmail-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`repEmail-${id}`)).value
      : null;
    let reqBody = {
      name: name,
      title: title,
      company: company,
      phone: phone,
      email: email,
    };
    if (!this._deepEqual(reqBody, this._lien_representative_state)) {
      this._http
        .put<UpdateRepresentativeData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/representative/update/${id}/`,
          reqBody
        )
        .subscribe((resp) => {
          this.lien_representative = [];
          this._loadLienRep();
        });
    }
    let editBut = document.querySelectorAll('.editRep');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  addRep() {
    let name = (<HTMLInputElement>document.getElementById(`repName`)).value
      ? (<HTMLInputElement>document.getElementById(`repName`)).value
      : null;
    let title = (<HTMLInputElement>document.getElementById(`repTitle`)).value
      ? (<HTMLInputElement>document.getElementById(`repTitle`)).value
      : null;
    let company = (<HTMLInputElement>document.getElementById(`repCompany`))
      .value
      ? (<HTMLInputElement>document.getElementById(`repCompany`)).value
      : null;
    let phone = (<HTMLInputElement>document.getElementById(`repPhone`)).value
      ? (<HTMLInputElement>document.getElementById(`repPhone`)).value
      : null;
    let email = (<HTMLInputElement>document.getElementById(`repEmail`)).value
      ? (<HTMLInputElement>document.getElementById(`repEmail`)).value
      : null;
    let reqBody = {
      representative_name: name,
      title: title,
      company: company,
      phone: phone,
      email: email,
    };
    this._http
      .post<UpdateRepresentativeData>(
        `${environment.fireStoreApiUrl}/api/v1/liens/representative/create/${this._claimNumber}/`,
        reqBody
      )
      .subscribe((resp) => {
        if (resp) {
          (<HTMLInputElement>document.getElementById(`repName`)).value = '';
          (<HTMLInputElement>document.getElementById(`repTitle`)).value = '';
          (<HTMLInputElement>document.getElementById(`repCompany`)).value = '';
          (<HTMLInputElement>document.getElementById(`repPhone`)).value = '';
          (<HTMLInputElement>document.getElementById(`repEmail`)).value = '';
          this.lien_representative = [];
          this._http
            .get<GetLienRepresantative>(
              `${environment.fireStoreApiUrl}/api/v1/liens/representative/all/?claim_number=${this._claimNumber}`
            )
            .subscribe((resp) => {
              if (resp.details.length > 0) {
                resp.details.forEach((el) => {
                  this.lien_representative.push({
                    representative_name: el.representative_name,
                    title: el.title,
                    company: el.company,
                    phone: el.phone,
                    email: el.email,
                    id: el.id,
                  });
                });
              }
            });
        }
      });
  }
  cancelRep(id: string) {
    document.getElementById(`editRep-${id}`).hidden = false;
    document.getElementById(`saveRep-${id}`).hidden = true;
    document.getElementById(`cancelRep-${id}`).hidden = true;
    document
      .getElementById(`repName-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repTitle-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repCompany-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repPhone-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`repEmail-${id}`)
      .setAttribute('disabled', 'disabled');
    (<HTMLInputElement>document.getElementById(`repName-${id}`)).value =
      this._lien_representative_state.representative_name;
    (<HTMLInputElement>document.getElementById(`repTitle-${id}`)).value =
      this._lien_representative_state.title;
    (<HTMLInputElement>document.getElementById(`repCompany-${id}`)).value =
      this._lien_representative_state.company;
    (<HTMLInputElement>document.getElementById(`repPhone-${id}`)).value =
      this._lien_representative_state.phone;
    (<HTMLInputElement>document.getElementById(`repEmail-${id}`)).value =
      this._lien_representative_state.email;
    let editBut = document.querySelectorAll('.editRep');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  deleteRep() {
    this._http
      .delete<any>(
        `${environment.fireStoreApiUrl}/api/v1/liens/representative/delete/${this._deleteRepId}/`
      )
      .subscribe((resp) => {
        if (resp) {
          this.lien_representative = [];
          this.addModal.dismissAll();
          this._http
            .get<GetLienRepresantative>(
              `${environment.fireStoreApiUrl}/api/v1/liens/representative/all/?claim_number=${this._claimNumber}`
            )
            .subscribe((resp) => {
              if (resp.details.length > 0) {
                resp.details.forEach((el) => {
                  this.lien_representative.push({
                    representative_name: el.representative_name,
                    title: el.title,
                    company: el.company,
                    phone: el.phone,
                    email: el.email,
                    id: el.id,
                  });
                });
              }
            });
        }
      });
  }
  openDeleteRep(id: number, content) {
    this._deleteRepId = id;
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }
  editRes(id: string) {
    document.getElementById(`editRes-${id}`).hidden = true;
    document.getElementById(`saveRes-${id}`).hidden = false;
    document.getElementById(`cancelRes-${id}`).hidden = false;
    document.getElementById(`resDate-${id}`).removeAttribute('disabled');
    document.getElementById(`resAmount-${id}`).removeAttribute('disabled');
    document
      .getElementById(`resEditWho-${id}`)
      .removeAttribute('disabled');
    document
      .getElementById(`resArgusDirected-${id}`)
      .removeAttribute('disabled');
    document.getElementById(`resHow-${id}`).removeAttribute('disabled');
    // document.getElementById(`resEditWho-${id}`).hidden = false;
    // document.getElementById(`resWho-${id}`).hidden = true;
    this._lien_resolution_state.resolved_date = (<HTMLInputElement>(
      document.getElementById(`resDate-${id}`)
    )).value;
    this._lien_resolution_state.resolved_amount = (<HTMLInputElement>(
      document.getElementById(`resAmount-${id}`)
    )).value;
    this._lien_resolution_state.who_resolved = (<HTMLInputElement>(
      document.getElementById(`resEditWho-${id}`)
    )).value;
    this._lien_resolution_state.argus_directed = (<HTMLSelectElement>(
      document.getElementById(`resArgusDirected-${id}`)
    )).value;
    this._lien_resolution_state.resolved_how = (<HTMLInputElement>(
      document.getElementById(`resHow-${id}`)
    )).value;
    let editBut = document.querySelectorAll('.editRes');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }
  saveRes(id: string) {
    const date = (<HTMLInputElement>document.getElementById(`resDate-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`resDate-${id}`)).value
      : null;
    const amount = (<HTMLInputElement>(
      document.getElementById(`resAmount-${id}`)
    )).value
      ? Number(
          (<HTMLInputElement>document.getElementById(`resAmount-${id}`)).value
        )
      : null;
    const who_resolved = (<HTMLSelectElement>(
      document.getElementById(`resEditWho-${id}`)
    )).value
      ? (<HTMLSelectElement>document.getElementById(`resEditWho-${id}`))
        .value
      : null;
    const directedIni = (<HTMLSelectElement>(
      document.getElementById(`resArgusDirected-${id}`)
    )).value
      ? (<HTMLSelectElement>document.getElementById(`resArgusDirected-${id}`))
          .value
      : null;
    const directed = directedIni === 'true';
    const how = (<HTMLInputElement>document.getElementById(`resHow-${id}`))
      .value
      ? (<HTMLInputElement>document.getElementById(`resHow-${id}`)).value
      : null;
    let reqBody = {
      resolved_date: date,
      resolved_amount: amount,
      who_resolved,
      argus_directed: directed,
      resolved_how: how,
      tax_id_number: this.claimantTable?.tin_number
    };

    if (
      !this._deepEqual(reqBody, this._lien_resolution_state)
    ) {
      this._http
        .put<UpdateResolutionData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/resolution/update/${id}/`,
          reqBody
        )
        .subscribe((resp) => {
          if (resp) {
            document.getElementById(`editRes-${id}`).hidden = false;
            document.getElementById(`saveRes-${id}`).hidden = true;
            document.getElementById(`cancelRes-${id}`).hidden = true;
            document
              .getElementById(`resDate-${id}`)
              .setAttribute('disabled', 'disabled');
            document
              .getElementById(`resAmount-${id}`)
              .setAttribute('disabled', 'disabled');
            document
              .getElementById(`resHow-${id}`)
              .setAttribute('disabled', 'disabled');
            document
              .getElementById(`resEditWho-${id}`)
              .setAttribute('disabled', 'disabled');
            document
              .getElementById(`resArgusDirected-${id}`)
              .setAttribute('disabled', 'disabled');
            this._getresEditWhoRslvdId = null;
            document.getElementById(`resWho-${id}`).hidden = false;
            this.lien_resolution = [];
            this._loadLienRes();
          }
        });
      let editBut = document.querySelectorAll('.editRes');
      for (let i = 0; i < editBut.length; i++) {
        editBut[i].removeAttribute('disabled');
      }
    }
  }
  public addLienRes() {
    const resolved_date = this.toServerDateFormat('resResDate');
    const resolved_amount = (<HTMLInputElement>(
      document.getElementById(`resResAmt`)
    )).value
      ? Number((<HTMLInputElement>document.getElementById(`resResAmt`)).value)
      : null;
    const resWhoRslvd = (<HTMLSelectElement>(
      document.getElementById(`resWhoRslvd`)
    )).value
      ? (<HTMLSelectElement>document.getElementById(`resWhoRslvd`)).value
      : null;
    const directedIni = (<HTMLSelectElement>(
      document.getElementById(`resArgusDirected`)
    )).value
      ? (<HTMLSelectElement>document.getElementById(`resArgusDirected`)).value
      : null;
    const directed = directedIni === 'true';
    const how = (<HTMLInputElement>document.getElementById(`resHow`)).value
      ? (<HTMLInputElement>document.getElementById(`resHow`)).value
      : null;
    const reqBody = {
      resolved_date: resolved_date,
      resolved_amount: resolved_amount,
      who_resolved: resWhoRslvd,
      argus_directed: directed,
      resolved_how: how,
      tax_id_number: this.claimantTable?.tin_number
    };
    if (resolved_date && resolved_amount && resWhoRslvd) {
      this._http
        .post<UpdateResolutionData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/resolution/create/${this._claimNumber}/`,
          reqBody
        )
        .subscribe((resp) => {
          if (resp) {
            (<HTMLInputElement>document.getElementById(`resResDate`)).value =
              '';
            (<HTMLInputElement>document.getElementById(`resResAmt`)).value = '';
            (<HTMLInputElement>document.getElementById(`resWhoRslvd`)).value =
              '';
            (<HTMLInputElement>document.getElementById(`resHow`)).value = '';
            (<HTMLSelectElement>(
              document.getElementById(`resArgusDirected`)
            )).value = '';
            this._resWhoRslvdId = null;
            this._loadLienRes();
          }
        });
    }
  }
  cancelRes(id: string) {
    document.getElementById(`editRes-${id}`).hidden = false;
    document.getElementById(`saveRes-${id}`).hidden = true;
    document.getElementById(`cancelRes-${id}`).hidden = true;
    document
      .getElementById(`resDate-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`resAmount-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`resEditWho-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`resArgusDirected-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`resArgusDirected-${id}`)
      .setAttribute('disabled','disabled');
    document
      .getElementById(`resHow-${id}`)
      .setAttribute('disabled','disabled');
    // (<HTMLInputElement>document.getElementById(`resEditWhoInput-${id}`)).value =
    //   '';
    // (<HTMLInputElement>document.getElementById(`resEditByInput-${id}`)).value =
    //   '';
    // document.getElementById(`resEditBy-${id}`).hidden = true;
    // document.getElementById(`resEditWho-${id}`).hidden = true;
    // document.getElementById(`resWho-${id}`).hidden = false;
    // document.getElementById(`resBy-${id}`).hidden = false;
    (<HTMLInputElement>document.getElementById(`resDate-${id}`)).value =
      this._lien_resolution_state.resolved_date;
    (<HTMLInputElement>document.getElementById(`resAmount-${id}`)).value =
      this._lien_resolution_state.resolved_amount;
    this._resWhoRslvdId = null;
    this._resResById = null;
    let editBut = document.querySelectorAll('.editRes');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  deleteRes() {
    this._http
      .delete<any>(
        `${environment.fireStoreApiUrl}/api/v1/liens/resolution/delete/${this._deleteResId}/`
      )
      .subscribe((resp) => {
        if (resp) {
          this.lien_resolution = [];
          this.addModal.dismissAll();
          this._loadLienRes();
        }
      });
  }
  openDeleteRes(id: number, content) {
    this._deleteResId = id;
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }
  editCorrectionNeeded(): void {
    this.isTextAreaCorrectionNeeded = true;
    this.textAreaCorrectionNeeded.enable();
  }

  editSRBillObjLng(): void {
    this.isTextAreaSRBillObjLng = true;
    this.textAreaSRBillObjLng.enable();
  }

  saveCorrectionNeeded(): void {
    if (
      this.claimantTable.correction_needed !=
      this.textAreaCorrectionNeeded.value
    ) {
      let updatedData = {
        correction_needed: this.textAreaCorrectionNeeded.value,
      };
      this._http
        .put<UpdateLimosData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this._claimNumber}/`,
          updatedData
        )
        .subscribe((resp) => {
          if (resp) {
            this.isTextAreaCorrectionNeeded = false;
            this.textAreaCorrectionNeeded.disable();
          }
        });
    } else {
      this.isTextAreaCorrectionNeeded = false;
      this.textAreaCorrectionNeeded.disable();
    }
  }

  saveSRBillObjLng(): void {
    if (
      this.claimantTable.bill_review_objection_language !=
      this.textAreaSRBillObjLng.value
    ) {
      let updatedData = {
        bill_review_objection_language: this.textAreaSRBillObjLng.value,
      };
      this._http
        .put<UpdateLimosData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this._claimNumber}/`,
          updatedData
        )
        .subscribe((resp) => {
          if (resp) {
            this.isTextAreaSRBillObjLng = false;
            this.textAreaSRBillObjLng.disable();
          }
        });
    } else {
      this.isTextAreaSRBillObjLng = false;
      this.textAreaSRBillObjLng.disable();
    }
  }

  editPrimaryTable(): void {
    this.isPrimaryTableEditable = true;
  }
  savePrimaryTable(): void {
    this.isPrimaryTableEditable = false;
    const formData = {
      siu_pay_code: (
        document.getElementById('siu_pay_code') as HTMLInputElement
      ).value,
      date_of_siu_objection: this.toServerDateFormat('date_siu_objection'),
      objection_from: this.toServerDateFormat('objection_from'),
      objection_to: this.toServerDateFormat('objection_to'),
      siu_objection_removed: (
        document.getElementById('siu_objection_removed') as HTMLInputElement
      ).value,
      bill_review_phone: (document.getElementById('phone') as HTMLInputElement)
        .value,
      bill_review_email: (document.getElementById('email') as HTMLInputElement)
        .value,
      total_billed: Number(
        (document.getElementById('tot_billed') as HTMLInputElement).value
      ),
      total_fee_schedule: Number(
        (document.getElementById('tot_fee_schdl') as HTMLInputElement).value
      ),
      total_paid: Number(
        (document.getElementById('tot_paid') as HTMLInputElement).value
      ),
    };
    const initialData = {
      siu_pay_code: this.claimantTable?.siu_pay_code,
      date_of_siu_objection: this.claimantTable?.date_siu_objection,
      objection_from: this.claimantTable?.objection_from,
      objection_to: this.claimantTable?.objection_to,
      siu_objection_removed: this.claimantTable?.siu_objection_removed,
      bill_review_phone: this.claimantTable?.phone,
      bill_review_email: this.claimantTable?.email,
      total_billed: Number(this.claimantTable?.tot_billed),
      total_fee_schedule: Number(this.claimantTable?.tot_fee_schdl),
      total_paid: Number(this.claimantTable?.tot_paid),
    };
    const updatedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        updatedData[key] = formData[key];
      }
    }
    if (Object.keys(updatedData).length === 0) {
      this.isPrimaryTableEditable = false;
    } else {
      this._http
        .put<UpdateLimosData>(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this._claimNumber}/`,
          updatedData
        )
        .subscribe((resp) => {
          if (resp) {
            this.isPrimaryTableEditable = false;
            this._loadLienProfile();
          }
        });
    }
  }

  download(fileUID: number) {
    this._http
      .get(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/download/${fileUID}/`
      )
      .subscribe((data: any) => {
        const a = document.createElement('a');
        a.href = data.details.signed_url;
        a.click();
      });
  }

  onFileSelected(event) {
    const file = <File>event.target.files[0];
    const fileData = new FormData();
    fileData.append('file', file, file.name);
    fileData.append('file_name', file.name);
    fileData.append('claim_number', this.claimantTable.wc_claim_number);
    fileData.append('description', `Uploaded File ${file.name}`);
    this._http
      .post(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/upload/`,
        fileData
      )
      .subscribe((data) => {
        if (data) {
          this._http
            .get<GetLimosDocument>(
              `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/all/?claim_number=${this.claimantTable.wc_claim_number}`
            )
            .subscribe((resp) => {
              if (resp.details.length > 0) {
                this.filesTable = [];
                resp.details.forEach((el) => {
                  this.filesTable.push({
                    file_name: el.file_name,
                    description: el.description,
                    id: el.id,
                  });
                });
              }
            });
        }
      });
  }

  deleteModalOpen(content, id: number): void {
    this._deleteFileId = id;
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  deleteConfirmation() {
    this._http
      .delete(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/delete/${this._deleteFileId}/`
      )
      .subscribe((resp) => {
        if (resp) {
          this.addModal.dismissAll();
          this._http
            .get<GetLimosDocument>(
              `${environment.fireStoreApiUrl}/api/v1/liens/limos-document/all/?claim_number=${this.claimantTable.wc_claim_number}`
            )
            .subscribe((resp) => {
              if (resp.details.length > 0) {
                this.filesTable = [];
                resp.details.forEach((el) => {
                  this.filesTable.push({
                    file_name: el.file_name,
                    description: el.description,
                    id: el.id,
                  });
                });
              }
            });
        }
      });
  }

  search1: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader1 = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this._http
              .get<UserSearchResult>(
                `${environment.fireStoreApiUrl}/api/v1/user/all/?q=${term}`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader1 = false;
                  }
                }),
                map((results) => {
                  this.userSerchResult = results;
                  const startsWithResults = this.userSerchResult.details.filter(
                    (result) =>
                      result.first_name
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                  );
                  const otherResults = this.userSerchResult.details.filter(
                    (result) =>
                      !result.first_name
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                  );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  search2: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader2 = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this._http
              .get<UserSearchResult>(
                `${environment.fireStoreApiUrl}/api/v1/user/all/?q=${term}`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader2 = false;
                  }
                }),
                map((results) => {
                  this.userSerchResult = results;
                  const startsWithResults = this.userSerchResult.details.filter(
                    (result) =>
                      result.first_name
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                  );
                  const otherResults = this.userSerchResult.details.filter(
                    (result) =>
                      !result.first_name
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                  );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  public formatter = (x: any) => x.first_name + ' ' + x.last_name;
  public getresWhoRslvdId(id: number) {
    this._resWhoRslvdId = id;
  }
  public getresResById(id: number) {
    this._resResById = id;
  }
  public getresEditWhoRslvdId(id: number) {
    this._getresEditWhoRslvdId = id;
  }
  public getresEditResById(id: number) {
    this._getresEditResById = id;
  }
  exportToPdf(spaceid: string, orientation: string) {
    // Define the element variable
    var element = document.getElementById(spaceid);

    // Select buttons and hide them
    const buttons = element.querySelectorAll('button');
    buttons.forEach((button) => (button.style.display = 'none'));

    const lastTbody = document.getElementById('last-tbody');
    if (lastTbody) {
      lastTbody.style.display = 'none';
    }

    var opt = {
      margin: 0.1,
      filename: `${this._claimantId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: {
        unit: 'in',
        format: 'a3',
        orientation: orientation === 'landscape' ? 'landscape' : 'portrait',
      },
    };

    // Generate the PDF
    html2pdf
      .default(element, opt)
      .then(() => {
        // Show the last tbody and buttons after PDF generation
        if (lastTbody) {
          lastTbody.style.display = 'table-row-group';
        }
        if (buttons) {
          buttons.forEach((button) => (button.style.display = 'inline-block'));
        }
      })
      .catch((error) => {
        console.error('PDF export failed: ', error);
      });

    this.addModal.dismissAll();
  }

  exportBR(content) {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  downloadCSV_billReviewTable() {
    const table = this.billReviewTable.nativeElement;
    const rows = table.querySelectorAll('tr');
    const csv = [];

    for (let i = 0; i < rows.length; i++) {
      const row = [];
      const cols = rows[i].querySelectorAll('td, th');

      for (let j = 0; j < cols.length; j++) {
        const txt = cols[j].innerText.replace(/,/g, '');
        row.push(txt);
      }

      csv.push(row.join(','));
    }

    const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table.csv');
    document.body.appendChild(link);
    link.click();
    this.addModal.dismissAll();
  }

  downloadCSV_lienResTable() {
    const table = this.lienResTable.nativeElement;
    const rows = table.querySelectorAll('tr');
    const csv = [];

    for (let i = 0; i < rows.length; i++) {
      const row = [];
      const cells = rows[i].querySelectorAll('td, th');

      for (let j = 0; j < cells.length; j++) {
        const cell = cells[j];
        let cellData;

        if (cell.querySelector('span')) {
          cellData = cell.querySelector('span').innerText;
        } else if (cell.querySelector('input')) {
          cellData = cell.querySelector('input').value;
        } else if (cell.querySelector('select')) {
          // Handle select elements to get the selected option value
          cellData = cell.querySelector('select').value;
        } else {
          cellData = cell.innerText;
        }

        cellData = cellData.replace(/,/g, '');
        row.push(cellData);
      }

      csv.push(row.join(','));
    }

    const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'LienResolution.csv');
    document.body.appendChild(link);
    link.click();
    this.addModal.dismissAll();
  }

  private _deepEqual(obj1, obj2) {
    // Check if both parameters are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }

    // Get the keys of the objects
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over the keys and compare the properties
    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !this._deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  updateLimosStatus() {
    const req = {
      file_status: this.limosStatus.value
    }
    this.limosService.updateLimosStatus(this._claimantId, this._claimNumber, req).subscribe((response: LimosDataResponse<UpdateLimosResponseDetails>) => {
      this.toastr.success(response?.details.message);
      this.claimantTable.file_status = this.limosStatus.value;
    });
  }

  setSavingAmount(rowId = null){
    if (rowId) {
      const resAmount = (
        document.getElementById(`resAmount-${rowId}`) as HTMLInputElement
      ).value;
      const savingAmount = +resAmount - this.liensTable?.lien_amount;
      const savingPercent = this.liensTable?.lien_amount ? ((+resAmount / this.liensTable?.lien_amount) * 100).toFixed(2) : 0;
      (<HTMLInputElement>document.getElementById(`savingAmount-${rowId}`)).value = savingAmount?.toString();
      (<HTMLInputElement>document.getElementById(`savingPercent-${rowId}`)).value = savingPercent?.toString() + '%';
    } else {
      const resAmount = (
        document.getElementById(`resResAmt`) as HTMLInputElement
      ).value;
      const savingAmount = +resAmount - this.liensTable?.lien_amount;
      const savingPercent = this.liensTable?.lien_amount ? ((+resAmount / this.liensTable?.lien_amount) * 100).toFixed(2) : 0;
      (<HTMLInputElement>document.getElementById(`savingAmt`)).value = savingAmount?.toString();
      (<HTMLInputElement>document.getElementById(`savingPercent`)).value = savingPercent?.toString() + '%';
    }
  }

  getBillReviewAndPaymentData() {
    this.brPaymentBlockUI.start();
    this.limosService.getBillReviewAndPaymentData(this._claimNumber, this.lienClaimantTin,this.billReviewAndPaymentsLimit,this.billReviewAndPaymentsPage).subscribe((billReviewAndPaymentRes: LimosDataResponse<BillReviewAndPaymentResponse>) => {
      const {hits,offset,estimatedTotalHits} = billReviewAndPaymentRes.details;
      this.billReviewAndPayments = hits;
      this.billReviewAndPaymentsTotal = estimatedTotalHits;
      this.billReviewAndPayments.map(data=>{
        data.bgColor = this.getBackgroundColor(data);
        return data;
      });
      this.brPaymentBlockUI.stop();
    }, error => {
      this.brPaymentBlockUI.stop();
    });
  }

  getRandomLightColor() {
    // Generate random values for R, G, B between 180 and 255 to ensure light colors
    const r = Math.floor(Math.random() * (255 - 180 + 1)) + 180;
    const g = Math.floor(Math.random() * (255 - 180 + 1)) + 180;
    const b = Math.floor(Math.random() * (255 - 180 + 1)) + 180;

    // Convert RGB to hex
    const color = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

    return `#${color}`;
  }

  getBackgroundColor(billReviewData: BillReviewAndPayment) {
    const { bill_review_br_process_date, bill_review_service_from_date, bill_review_service_through_date } = billReviewData;
    const columnsForColor = (bill_review_br_process_date ? bill_review_br_process_date : '')
    + (bill_review_service_from_date ? bill_review_service_from_date : '')
    + (bill_review_service_through_date ? bill_review_service_through_date : '');
    if (this.columnsForColor != columnsForColor) {
      this.randomColor = this.getRandomLightColor();
      this.columnsForColor = columnsForColor;
    }
    return this.randomColor;
  }

  getSimilarClaims() {
    this.similarClaimBlockUI.start();
    this.limosService.getSimilarClaims(this.claimantTable.tax_id_number, this.similarClaimsLimit, this.similarClaimsPage).subscribe((response: LimosDataResponse<SimilarClaimRes>) => {
      const { hits, offset, estimatedTotalHits } = response.details;
      this.similar_claims = hits;
      this.similarClaimsTotal = estimatedTotalHits;
      this.similarClaimBlockUI.stop();
      this.similarClaimsDataLoaded = true;
    }, error => {
      this.similarClaimBlockUI.stop();
      this.similarClaimsDataLoaded = true;
    });
  }

  onNoteFileSelected(event: any) {
    this.selectedNoteFiles = Array.from(event.target.files).filter(file => this.isValidFileType(file));
    this.selectedNoteFiles.map(file => {
      file.formattedSize = this.formatFileSize(file.size);
      return file;
    });
  }

  isValidFileType(file: any): boolean {
    const allowedTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    return allowedTypes.includes(file.type);
  }

  formatFileSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, i);
    return `${size.toFixed(2)} ${sizes[i]}`;
  }

  addNoteTags(event: any){
    const value = event.target.value ? event.target.value.trim() : '';
    const isTagExist = this.selectedNoteTags.find(tag => tag == value);
    if(value && !isTagExist){
      this.selectedNoteTags.push(value);
    }
    event.target.value = '';
  }

  createNotepad(timeSpentContent){
    this.addModal.dismissAll();
    if(this.createExpenseEntry){
      this.openAddNoteModal(timeSpentContent,'sm');
    }else{
      this.createExpense();
    }
  }

  createExpense(){
    this.addModal.dismissAll();
    this.createExpenseEntry = false;
    this.selectedNoteTags = this.selectedNoteFiles = [];
  }

  openNotefilesInputDialog(notefilesInput){
    notefilesInput.click();
  }

  onSizeChangeSimilarClaims(limit: number) {
    this.similarClaimsLimit = limit;
    this.similarClaimsPage = 1;
    this.getSimilarClaims();
  }

  onPageChangeSimilarClaims(event: any) {
    this.similarClaimsPage = event;
    this.getSimilarClaims();
  }

  onSizeChangeBillReviewAndPayments(limit: number) {
    this.billReviewAndPaymentsLimit = limit;
    this.billReviewAndPaymentsPage = 1;
    this.getBillReviewAndPaymentData();
  }

  onPageChangeBillReviewAndPayments(event: any) {
    this.billReviewAndPaymentsPage = event;
    this.getBillReviewAndPaymentData();
  }

  similarClaimTabClick(){
    setTimeout(() => {
      if(!this.similarClaimsDataLoaded){
        this.similarClaimBlockUI.start();
      }
    });
  }

  exportSimilarClaims() {
    console.log(this.claimantTable?.tin_number);
    this.similarClaimExportLoader = true;
    this.limosService.getSimilarClaimTaskId(this.claimantTable.tax_id_number, this.similarClaimsLimit, this.similarClaimsPage).subscribe((response: LimosDataResponse<LimosReportCSVExportResponse>) => {
      this.checkLimosReportExportStatus(response?.details?.task_id);
      return;
    },error=>{
      this.similarClaimExportLoader = false;
    });
  }

  checkLimosReportExportStatus(taskId: string) {
    if (taskId) {
      const interVal = setInterval(() => {
        this.limosService.checkLimosReportTaskStatus(taskId).subscribe((x: LimosDataResponse<TaskStatus>) => {
          if (x.details.status == 'SUCCESS') {
            clearInterval(interVal);
            const a = document.createElement("a");
            a.href = x.details.result.signed_url;
            a.download = "record.csv";
            a.click();
          }
          this.similarClaimExportLoader = false;
        });
      }, 5000);
    }
  }
}
